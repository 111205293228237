import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { ColorExtractor } from "react-color-extractor";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCraousalEvent } from "../../redux/actions/Admin/AdminActions";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import { useNavigate } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ShareModal from "../EventCard/LatestCard/ShareModal";
import locationSvg from "../../assets/svg/location-dot.svg";
import calenderSvg from "../../assets/svg/calendar.svg";
import ClockSvg from "../../assets/svg/clock.svg";
import TicketSvg from "../../assets/svg/ticketsvg.svg";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Carousal01.css";
import 'react-loading-skeleton/dist/skeleton.css';


const Carousal01 = () => {
  const carousalData = useSelector((state) => state.Admin.carousalData);
  const isLoding = useSelector((state) => state.Admin.adminloading);
  const [backgroundColors, setBackgroundColors] = useState([]);
  const [Share, setShare] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleColors = (colors, index) => {
    if (colors && colors.length > 0) {
      setBackgroundColors((prevColors) => {
        const newColors = [...prevColors];
        newColors[index] = colors;
        return newColors;
      });
    }
  };

  useEffect(() => {
    if (carousalData?.length === 0) {
      dispatch(GetAllCraousalEvent());
    }
    // eslint-disable-next-line
  }, []);

  const handleNavigation = (eventId, dataToSend, image) => {
    navigate(`/tickets/group.details/${eventId}`, {
      state: { data: dataToSend, image: image },
    });
  };

  return (
    <>
      <div className={`Carousal01 w-full  rounded-[20px] h-[80vh]`}>
        {isLoding ? (
          <SkeletonTheme baseColor="#2c2c2c" highlightColor="gray"
            width={"100%"}
            height={"80vh"}
          >
            <p>
              <Skeleton width={"100%"} height={"80vh"} borderRadius={16} duration={3} />
            </p>
          </SkeletonTheme>


        ) : (
          <>
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{ delay: 3000 }}
              modules={[EffectFade, Navigation, Pagination, Autoplay]}
              className="w-[100%] h-[100%]"
            // onSlideChange={(swiper) => {
            //   const activeIndex = swiper.realIndex;
            //   const paginationBullets = document.querySelectorAll(
            //     ".swiper-pagination-bullet"
            //   );
            //   var parentView = document.querySelectorAll(".Carousal01");

            //   paginationBullets?.forEach((bullet, index) => {
            //     if (index === activeIndex) {
            //       bullet.style.setProperty(
            //         "background-color",
            //         backgroundColors[index] && backgroundColors[index][0],
            //         "important"
            //       );
            //       if (parentView && parentView[0]) {
            //         parentView[0].style.boxShadow = `0px 0px 7px 1px ${
            //           backgroundColors[index] && backgroundColors[index][0]
            //         }`;
            //       }
            //     } else {
            //       bullet.style.removeProperty("background-color");
            //     }
            //   });
            // }}
            >
              {carousalData &&
                carousalData?.map((item, index) => {
                  if (
                    Date.now() > new Date(item?.event[0]?.occurs_at)?.getTime()
                  ) {
                    // eslint-disable-next-line
                    return;
                  }
                  const activeElement = document.querySelector(
                    ".swiper-pagination-bullet-active"
                  );
                  var parentView = document.querySelectorAll(".Carousal01");
                  if (parentView && parentView[0]) {
                    parentView[0].style.boxShadow = `0px 0px 7px 1px #e40434 `;
                  }
                  // ${
                  //     backgroundColors[0] && backgroundColors[0][0]
                  //   }

                  if (activeElement) {
                    activeElement.style.setProperty(
                      "background-color",
                      // backgroundColors[0] && backgroundColors[0][0],
                      "#e40434",
                      "important"
                    );
                  }
                  return (
                    <SwiperSlide
                      className="carousalbox bg-black z-10"
                      key={index}
                      onMouseEnter={() => {
                        var parentView =
                          document.querySelectorAll(".Carousal01");
                        if (parentView && parentView[0]) {
                          parentView[0].style.boxShadow = `0px 0px 20px 2px #e40434 `;
                          // ${
                          //   backgroundColors[index] && backgroundColors[index][0]
                          // }
                        }
                      }}
                      onMouseLeave={() => {
                        var parentView =
                          document.querySelectorAll(".Carousal01");
                        if (parentView && parentView[0]) {
                          parentView[0].style.boxShadow = `0px 0px 7px 1px #e40434 `;
                        }
                        // ${
                        //   backgroundColors[index] && backgroundColors[index][0]
                        // }
                      }}
                    >
                      <div className="w-full h-full relative ">
                        <ColorExtractor
                          getColors={(colors) => handleColors(colors, index)}
                        >
                          <img
                            className="carousalbox_img_hero"
                            // src={item.image}
                            src={`${ApiMediaURL}/${item?.image}`}
                            alt=""
                          />
                        </ColorExtractor>
                        {item &&
                          item?.isOverLay &&
                          item?.isOverLay === true && (
                            <div
                              className="w-full h-full bg-gradient z-10 absolute top-0 left-0 opacity-[.8]"
                              style={{
                                background: `rgb(0,0,0)`,
                                backgroundImage: `linear-gradient(to right bottom,  ${backgroundColors[index]
                                  ? " #e40434"
                                  : " #e40434"
                                  }
                   
                    , #020202)`,
                              }}
                            ></div>
                          )}
                      </div>
                      <div className="carousalbox_content text-color2 relative  h-[100%]">
                        <div
                          className={`w-[250px] h-[250px] absolute z-[20] top-[-5px] right-[-5px] blur-[90px] opacity-[.9]`}
                          style={{
                            background: `rgb(0,0,0)`,
                            backgroundImage: `linear-gradient(to top, #020202, ${backgroundColors[index]
                              ? "#e40434"
                              : // backgroundColors[index][0]
                              "#e40434"
                              }
                   
                )`,
                          }}
                        ></div>
                        <div
                          className={`cursor-pointer w-[40px] h-[30px] absolute top-[15px] right-[10px] card_uper_vector1 z-[9999]`}
                        >
                          <FaShareAlt
                            className={`text-white z-40 text-[22px]`}
                            onClick={() => setShare(!Share)}
                          />
                        </div>
                        <h1
                          className={`text-[33px] font-[700] mb-[10px]`}
                          style={{
                            color: "#e40434",
                            // backgroundColors &&
                            // backgroundColors[index] &&
                            // backgroundColors[index][0],
                          }}
                        >
                          {item &&
                            item?.event &&
                            item?.event[0]?.name?.length > 21
                            ? `${item?.event[0]?.name.slice(0, 20)}...`
                            : item?.event[0]?.name}
                        </h1>
                        <p className="text-[18px] text-color2 pr-2">
                          {item &&
                            item?.paragraph &&
                            item?.paragraph?.length > 100
                            ? `${item?.paragraph?.slice(0, 100)}...`
                            : item?.paragraph}
                        </p>
                        <div className="carousalbox_locations ">
                          <div className="carousalbox_locations_box">
                            {/* <IoLocationOutline /> */}
                            <ReactSVG src={locationSvg} />
                            <p
                              className=""
                              style={{
                                color: "#e40434",
                                // background: `-webkit-linear-gradient(top left, #333, ${
                                //   backgroundColors[index] &&
                                //   backgroundColors[index][0]
                                // } )`,
                                // WebkitBackgroundClip: "text",
                                // WebkitTextFillColor: "transparent",
                              }}
                            >
                              {item &&
                                item?.event &&
                                item?.event[0]?.venue?.location &&
                                item?.event[0]?.venue?.location?.length > 16
                                ? `${item?.event[0]?.venue?.location?.slice(
                                  0,
                                  16
                                )}..`
                                : item?.event[0]?.venue?.location}
                            </p>
                          </div>
                          <div className="carousalbox_locations_box">
                            {/* <MdOutlineCalendarMonth /> */}
                            <ReactSVG src={calenderSvg} />
                            <p
                              className=""
                              style={{
                                color: "#e40434",
                                // background: `-webkit-linear-gradient(top left, #333, ${
                                //   backgroundColors[index] &&
                                //   backgroundColors[index][0]
                                // } )`,
                                // WebkitBackgroundClip: "text",
                                // WebkitTextFillColor: "transparent",
                              }}
                            >
                              {item &&
                                item?.event[0]?.occurs_at_local.slice(0, 10)}
                            </p>
                          </div>
                          <div className="carousalbox_locations_box">
                            {/* <CiClock1 /> */}
                            <ReactSVG src={ClockSvg} />
                            <p
                              className=""
                              style={{
                                // color:
                                // backgroundColors &&
                                // backgroundColors[index] &&
                                // backgroundColors[index][0],
                                color: "#e40434",
                                // background: `-webkit-linear-gradient(top left, #333, ${
                                //   backgroundColors[index] &&
                                //   backgroundColors[index][0]
                                // } )`,
                                // WebkitBackgroundClip: "text",
                                // WebkitTextFillColor: "transparent",
                              }}
                            >
                              {item?.event[0]?.occurs_at_local
                                ? new Date(
                                  item?.event[0]?.occurs_at_local
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })
                                : `------`}
                              {/* 4:00 PM */}
                            </p>
                          </div>
                        </div>

                        <button
                          onClick={() =>
                            handleNavigation(
                              item?.event[0]?.id,
                              item?.event,
                              `${ApiMediaURL}/${item?.image}`
                            )
                          }
                          className="my-[22px] z-[9999999] carousal_content_btn flex justify-center place-items-center px-[80px] py-[15px] rounded-[10.33px] cursor-pointer gap-[8px]"
                          style={{
                            backgroundColor: "#e40434",
                            // backgroundColors &&
                            // backgroundColors[index] &&
                            // backgroundColors[index][0],
                          }}
                        >
                          {/* <IoTicketOutline className="text-[25px]" /> */}
                          <ReactSVG src={TicketSvg} />
                          <p className="text-[15.5px] font-[400px] text_option">
                            Get Tickets
                          </p>
                        </button>
                        {Share && (
                          <ShareModal
                            setShow={setShare}
                            item={item?.event && item?.event[0]}
                            Share={Share}
                          />
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </>
        )}
      </div>
    </>
  );
};

export default Carousal01;
