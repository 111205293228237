import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CreateOrder,
  GetAllEvents,
  GetEventsDeatils,
  GetGroupDeatils,
} from "../redux/actions/Events";
import Spinner from "../Components/Loading/Spinner";
import { AddtoCart } from "../redux/actions/Cart";
import toast from "react-hot-toast";
import { CreateUserOrder } from "../redux/actions/UserOrder";
import Index from "../Components/PagesCompoents/GroupDeatils/Index";

const GroupDetails = () => {
  const [section, setSection] = useState("");
  const [row, setrow] = useState("");
  const [groupId, setGroupId] = useState(null);
  const [price, setprice] = useState("");
  const [cart, setCart] = useState([]);
  const [qty, setQTY] = useState(null);
  const params = useParams();

  const { id } = params;
  const dispatch = useDispatch();
  const AllEvents = useSelector((state) => state.events.AllEvents);
  useEffect(() => {
    dispatch(GetEventsDeatils(parseInt(id)));
    // if (AllEvents && AllEvents?.length === 0) {
    // }
  }, [id]);
  const GroupsEvents = useSelector((state) => state.events.EventDetails);

  //   ------- select the section which are not duplicate
  const NotDuplicate = [];
  GroupsEvents?.forEach((item) => {
    if (NotDuplicate.includes(item.tevo_section_name)) {
      return;
    } else {
      NotDuplicate.push(item.tevo_section_name);
    }
  });
  const [SelectedRowArray, setSelectedRowArray] = useState([]);
  useEffect(() => {
    const filterArray = GroupsEvents?.filter(
      (item) => item.tevo_section_name == section
    );
    setSelectedRowArray(filterArray);
  }, [section]);
  //   -------- now get selected object
  useEffect(() => {
    dispatch(GetGroupDeatils(groupId));
  }, [row, groupId]);

  const GroupObj = useSelector((state) => state.events.GroupsEvents);
  const isLoading = useSelector((state) => state.events.isLoading);
  //   ========= increment and decriment func
  const increment = () => {
    if (Object.keys(GroupObj).length > 0) {
      if (qty < GroupObj?.available_quantity) {
        setQTY(qty + 1);
      } else {
        setQTY(GroupObj?.available_quantity);
      }
    }
  };
  const decriment = () => {
    if (Object.keys(GroupObj).length > 0) {
      if (qty == 1) {
        setQTY(1);
      } else {
        setQTY(qty - 1);
      }
    }
  };

  // -----  add to card
  const cartData = useSelector((state) => state.cart.cartData);
  const addtocart = () => {
    const isExist = cartData?.filter((item) => item.id === GroupObj.id);
    // const isExistqty = cartData?.filter((item) => item.qty === qty);
    // console.log(qty);
    // console.log(isExistqty);
    // console.log(GroupObj.splits);
    if (isExist?.length > 0) {
      return toast.error(`Ticket already exists in cart`, { duration: 5000 });
    }
    if (!row) {
      return toast.error("Plaese select the row");
    }
    if (row) {
      dispatch(
        AddtoCart({
          name: GroupObj?.event?.name,
          id: GroupObj?.id,
          retail_price: GroupObj?.retail_price,
          qty: qty,
          type: GroupObj?.format,
        })
      );
    }
  };

  useEffect(() => {
    var myElement = document.getElementById("map");

    if (isLoading) {
      if (myElement) {
        return myElement.style.setProperty("display", "none", "important");
      }
    } else {
      if (myElement) {
        myElement.style.setProperty("display", "block", "important");
      }
    }
    return () => {
    };
  }, [isLoading]);

  return (
    <div className="my-2">
      <Index id={id} />
    </div>
  );
};

export default GroupDetails;

