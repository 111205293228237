import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../../redux/actions/User";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { FiLock } from "react-icons/fi";
import LoginAuth from "../PrivateRoutes/LoginAuth";
import "./Login.css";

const Login = () => {
  const isLoading = useSelector((state) => state.user.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  


  const dataSubmit = (e) => {
    e.preventDefault();
    dispatch(UserLogin(email, password, navigate));
  };

  useEffect(() => {
    if (isLoading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [isLoading]);

  return (
    <LoginAuth>
      <div className="bg-color1 my-2 w-full flex justify-center place-items-center py-10 min-h-[100vh]">
        <div class="login-box">
          <h2 className="">Login to your account</h2>
          <form onSubmit={dataSubmit}>
            <div class="user-box">
              <input
                type="email"
                name=""
                required=""
                value={email}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Enter your email address"
                className="mt-3"
              />
              <label className="!text-[18px] !text-color5">Email Address</label>
            </div>
            <div class="user-box my-3">
              <input
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                type="password"
                name=""
                required=""
                placeholder="Enter your password"
                className="mt-3"
              />
              <label className="!text-[18px] mb-2 !text-color5">Password</label>
            </div>
            <button
              type="submit"
              className="w-full border-[1px] border-color2 text-color2 py-3 rounded-md my-3 px-2 cursor-pointer
          hover:bg-color4 hover:border-color4
          "
            >
              Login
            </button>
            <NavLink to="/registration">
              <p className="text-center text-color5 mt-2 cursor-pointer">
                Dont have an account?{" "}
                <span className="text-redColor">Signup</span>
              </p>
            </NavLink>
            <div className="w-full flex justify-end mt-[20px]">
              <NavLink
                to={"/forgotpassword"}
                className="text-[gray] text-[15px]"
              >
                <p className="flex justify-start place-items-center gap-[5px]">
                  Forgot Password
                  <span>
                    <FiLock />
                  </span>
                </p>
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </LoginAuth>
  );
};

export default Login;
