import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../../setting/GlobleVariable";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { LogoutUserFunc } from "../../../redux/actions/User";




const Header = ({setActive}) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  return (
    <>
      <div className=" w-full flex items-center justify-between h-14 text-white z-10 bg-color3 min-h-[80px] px-[20px]">
        <div></div>
        {/* =================  */}
        <div className="flex  place-items-center gap-3">
          <div>
            <h2>{user?.firstName && user?.lastName && user?.firstName + " " + user?.lastName}</h2>
          </div>
          <div className="dropdown dropdown-end ">
            <div className="flex place-items-center">
              <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar flex place-items-center gap-1">
                {
                  user?.Avatar ?
                    <div className="w-10 rounded-full">
                      <img
                        alt="profileImage"
                        src={`${ApiMediaURL}/${user?.Avatar}`} />
                    </div>
                    :
                    <div className="avatar placeholder">
                      <div className="bg-neutral text-neutral-content w-10 rounded-full">
                        <span>{user?.firstName && user?.lastName && user?.firstName?.slice(0, 1) + user?.lastName?.slice(0, 1)}</span>
                      </div>
                    </div>
                }
              </div>
              <IoMdArrowDropdown className="text-[20px] outline-none" tabIndex={0} role="button" />
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content gap-2  rounded-box z-[1] mt-3 w-52 p-2 shadow !bg-[black] !text-[white] shadow-[white]">
              <li className="hover:bg-[#272727] rounded-box"
                onClick={()=> setActive(3)}
              >
                <p className="justify-between">
                  Profile
                  <span className="badge">
                    <FaUserAlt />
                  </span>
                </p>
              </li>
              {/* <li className="hover:bg-[#272727] rounded-box"><a>Notifications</a></li> */}
              <li className="hover:bg-[#272727] rounded-box"
              onClick={()=>{
                 dispatch(LogoutUserFunc(navigate));
              }}
              >
                <p>Logout
                <span className="badge">
                  <CiLogout className="text-black"/>
                </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  );
};

export default Header;
