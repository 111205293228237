import React from "react";
import CustomerTable from "./CustomerTable";
import { useSelector } from "react-redux";

const Index = ({ setActive }) => {
  const AdminUsers = useSelector((state) => state.user.AdminAllUser);

  return (
    <div className="py-5 px-5 min-h-[90vh]">
      <h2 className="text-[27px] font-bold">
        Customers ({AdminUsers && AdminUsers?.length})
      </h2>
      <div className="">
        <CustomerTable setActive={setActive} />
      </div>
    </div>
  );
};

export default Index;
