import React from "react";
import Feeds from "../Components/PagesCompoents/NewsFeeds/Feeds";
import {useParams} from "react-router-dom";

const NewsFeeds = () => {
  const {id} = useParams();
  
  return (
    <>
      <Feeds id={id}/>
    </>
  );
};

export default NewsFeeds;
