import React, { useState, useEffect } from "react";
import dropin from "braintree-web-drop-in";
import { ApiURL } from "../../setting/GlobleVariable";
import toast from "react-hot-toast";
import Spinner from "../Loading/Spinner";
import { useSelector } from "react-redux";

const CreditCardPayment = ({
  show,
  setPaymentNonce,
  onPaymentCompleted,
  totalamount,
}) => {
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [methodName, setMethodName] = useState("");
  const [loading, setloading] = useState(false);
  const [payment, setPayment] = useState(false);

  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  useEffect(() => {
    const initializeBraintree = async () => {
      if (show === true) {
        try {
          setloading(true);
          const response = await fetch(`${ApiURL}/payment/generateClient`, {
            method: "GET",
            headers: {
              token: localStorage.getItem("instapass_user_token"),
            },
          });
          const data = await response.json();

          const { clientToken } = data;
          if (response.status === 400 || response.status === 500) {
            return toast.error("Plaese Login to continue");
          }

          var instance = await dropin.create({
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            card: {
              flow: "vault",
              amount:
                singleCouponCode && singleCouponCode?.discount
                  ? totalamount - singleCouponCode?.discount <= 0
                    ? 0
                    : totalamount - singleCouponCode?.discount
                  : totalamount,
              currency: "USD",
            },
          });
          setloading(false);
          setBraintreeInstance(instance);

          instance.on("paymentMethodRequestable", async (event) => {
            try {
              // Use the event object to get the paymentMethodNonce
              const { nonce } =
                await event.currentTarget.requestPaymentMethod();
              console.log(nonce, "payment methods nonce");
            } catch (error) {
              console.error("Error handling payment:", error);
            }
          });

          instance.on("noPaymentMethodRequestable", () => {
            console.log("No payment method is requestable");
          });

          instance.on("paymentOptionSelected", (event) => {
            setMethodName(event.paymentOption.id);
          });
        } catch (error) {
          console.error("Error initializing Braintree:", error);
        }
      }
    };

    if (show === true) {
      initializeBraintree();
    } else {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    }

    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    };
  }, [show]);

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id="braintree-drop-in-div" />
      {loading ? (
        <div className="w-full flex justify-center place-items-center">
          <Spinner />
        </div>
      ) : payment ? null : (
        <button
          className="bg-[black] text-[white] px-6 py-2 cursor-pointer"
          type="primary"
          disabled={!braintreeInstance}
          onClick={async () => {
            if (braintreeInstance) {
              braintreeInstance.requestPaymentMethod(async (error, payload) => {
                if (error) {
                  console.error(error);
                } else {
                  const paymentMethodNonce = payload.nonce;
                  try {
                    console.log(paymentMethodNonce, "paymentMethodNonce");
                    // Make an API call to your backend with the nonce and amount
                    const response = await fetch(
                      `${ApiURL}/payment/process/payment`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          payment_method_nonce: paymentMethodNonce,
                          amount: totalamount,
                        }),
                      }
                    );

                    if (response.status === 200) {
                      // Payment was successful
                      setPayment(true);
                      toast.success(`Payment completed`);
                      setPaymentNonce(paymentMethodNonce);
                      onPaymentCompleted();
                    } else {
                      // Handle the error from your backend
                      const responseData = await response.json();
                      console.error(
                        "Error processing payment:",
                        responseData.message
                      );
                      // Display an error message to the user
                      toast.error(
                        "Error processing payment. Please try again."
                      );
                    }
                  } catch (error) {
                    console.error("Error processing payment:", error);
                    // Display a generic error message to the user
                    toast.error("Error processing payment. Please try again.");
                  }
                }
              });
            }
          }}
        >
          {"Pay"}
        </button>
      )}
    </div>
  );
};

export default CreditCardPayment;
