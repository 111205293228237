import React, { useEffect, useState } from "react";
import PaymentMethods from "../Components/PagesCompoents/GroupDeatils/Sidebar/PaymentMethods";
import GoogleSvg from "../assets/svg/Gpay.svg";
import PaypalSvg from "../assets/svg/paypal.svg";
import AppleSvg from "../assets/svg/Apple.svg";
import CreditSvg from "../assets/svg/Credit card.svg";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Components/Loading/Spinner";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Loading } from "notiflix";



const NewPaymentPage = ({
  filterevent,
  receivedData,
  SingleTicket,
  SetSingleTicket,
  payWith,
  setPayWith,
  getOrderData,
  setIsPayment,
  data,
  singleCouponCode,
  setcoupon,
  coupon,
  RemoveCoupon,
  ApplyCoupon,
  CouponLoading,
  PaymentInfoData, setPaymentInfoData
}) => {
  const [active, setActive] = useState(1);
  const isPaymentInfoDataValue = PaymentInfoData.fname && PaymentInfoData.lname && PaymentInfoData.email  ? true : false;
  const params = useParams();
  const dispatch = useDispatch();
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const isloadingUserOrder = useSelector((state) => state.order.isloading)

  const HnadleActiveChanged = () => {
    if (active > 1) {
      setActive(active - 1)
    }
  }

  const handlePaymentInfo = (e) => {
    e.preventDefault();
    if (isPaymentInfoDataValue) {
      setActive(2)
    } else {
      toast.error("Please fill all the required fields")
    }
    console.log(PaymentInfoData)

  }

  useEffect(() => {
    if (isloadingcreateOrder || isloadingUserOrder) {
      Loading.standard("Loading Plaese Wait");
    }
    else {
      Loading.remove();
    }

  }, [isloadingcreateOrder, isloadingUserOrder])


  // handleRemoveCoupon
  const handleRemoveCoupon = () => {
    dispatch(RemoveCoupon());
    setcoupon("");
  }

  /// handleApplyCoupon
  const handleApplyCoupon = async () => {
    if (!coupon) {
      toast.error("Coupon field is required");
      return;
    }
    await dispatch(ApplyCoupon(coupon));
  }


  return (
    <>
      <section class=" md:py-5 antialiased my-[50px]  bg-black">
        <div class="mx-auto max-w-screen-xl px-4 2xl:px-0  ">
          <h3 class="text-[35px] font-semibold text-[white] dark:text-white md:mb-[50px] mb-[15px] flex gap-3 place-items-center">
            <IoIosArrowRoundBack className="text-[50px] cursor-pointer" onClick={() => setIsPayment(false)} /> Payment
          </h3>

          <div class="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div class="min-w-0 flex-1 space-y-8">
              <div class="space-y-4">
                <h3 class="md:text-[2vw] text-[4vw]  font-semibold text-[white] dark:text-white">
                  {(filterevent && filterevent[0]?.name) ||
                    (receivedData && receivedData[0]?.name) || params?.name}p
                </h3>

                {/* <Steps active={active} setActive={setActive} /> */}

                {active === 1 && <PaymentInfo
                  PaymentInfoData={PaymentInfoData}
                  setPaymentInfoData={setPaymentInfoData}
                  setActive={setActive}
                  active={active}
                />}

                <h2 class="text-2xl font-semibold text-gray-500 dark:text-white !my-5">
                  Payment Method
                </h2>

                {active === 1 && (
                  <div>
                    <label
                      for="voucher"
                      class="mb-2 block text-sm font-medium text-[white] dark:text-white"
                    >
                      {" "}
                      Enter a promotional code{" "}
                    </label>
                    <div className="max-w-[100%] flex gap-2 mb-5 !text-[white]">
                      <div className="w-[100%] relative">
                        <input
                          type="text"
                          placeholder="enter promo code"
                          className="w-[100%] py-2 px-3 rounded-md outline-none border-none bg-black"
                          style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                          }}
                          value={coupon}
                          onChange={(e) => setcoupon(e.target.value)}
                        />
                        <RxCross2 className="absolute right-3 top-[10px] z-10 cursor-pointer text-[22px]"
                          onClick={handleRemoveCoupon}
                        />
                      </div>
                      {CouponLoading ?
                        <button className="w-[150px] bg-[#ac332580] rounded-md text-[white] py-1 px-4 cursor-not-allowed">
                          <Spinner />
                        </button>

                        :
                        <button className="w-[150px] bg-[#AC3325] rounded-md text-[white]"
                          onClick={handleApplyCoupon}
                        >
                          Apply
                        </button>
                      }

                    </div>
                  </div>
                )}


                {active === 1 && (
                  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <PaymentMethods svg={CreditSvg} title={"Credit Card"} payWith={1} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={PaypalSvg} title={"Paypal"} payWith={2} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={AppleSvg} title={"Apple Pay"} payWith={3} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={GoogleSvg} title={"Google Pay"} payWith={4} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                  </div>
                )}
              </div>
              {/* <div class="sm:col-span-2 flex justify-end gap-4">
                {
                  active > 1 &&
                  <button
                    onClick={HnadleActiveChanged}
                    class="flex w-[150px] items-center justify-center gap-2 rounded-lg  bg-[#962B20] px-5 py-2.5 text-sm font-medium text-[white] hover:bg-[#86251a] hover:text-primary-700 "
                  >
                    Back
                  </button>
                }
                {
                  active === 1 &&
                  <button
                    onClick={handlePaymentInfo}
                    class="flex w-[150px] items-center justify-center gap-2 rounded-lg  bg-[#962B20] px-5 py-2.5 text-sm font-medium text-[white] hover:bg-[#86251a] hover:text-primary-700 "
                  >
                    Continue
                  </button>
                }

              </div> */}
            </div>

            {
              active >= 1 &&
              <div class="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md !sticky !top-[50px]">
                <h2 class="text-xl font-semibold text-gray-500 dark:text-white">
                  Order Summery
                </h2>
                <div class="flow-root">
                  <div class="-my-3 divide-y divide-gray-200 dark:divide-gray-800">
                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Price/seat
                      </dt>
                      <dd class="text-base font-medium text-[white] dark:text-white">
                        ${data?.retail_price}
                      </dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Quantity
                      </dt>
                      <dd class="text-base font-medium text-[white] dark:text-white">
                        {data?.qty}
                      </dd>
                    </dl>

                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Service fee
                      </dt>
                      <dd class="text-base font-medium text-green-500">
                        {data?.service_fee}
                      </dd>
                    </dl>

                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Discount
                      </dt>
                      <dd class="text-base font-medium text-green-500">${singleCouponCode && singleCouponCode?.discount
                        ? singleCouponCode?.discount
                        : "0"}</dd>
                    </dl>

                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Tax
                      </dt>
                      <dd class="text-base font-medium text-[white] dark:text-white">
                        $0
                      </dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-normal text-gray-500 dark:text-gray-400">
                        Subtotal
                      </dt>
                      <dd class="text-base font-medium text-[white] dark:text-white">
                        $ {data?.retail_price} X {data?.qty} ={" "}
                        {data?.retail_price * data?.qty}
                      </dd>
                    </dl>
                    <dl class="flex items-center justify-between gap-4 py-3">
                      <dt class="text-base font-bold text-gray-500 dark:text-white">
                        Total
                      </dt>
                      <dd class="text-base font-bold text-[white] dark:text-white">
                        $ {singleCouponCode && singleCouponCode?.discount
                          ? data?.retail_price * data?.qty +
                            data?.service_fee -
                            singleCouponCode?.discount <=
                            0
                            ? 0
                            : data?.retail_price * data?.qty +
                            data?.service_fee -
                            singleCouponCode?.discount
                          : data?.retail_price * data?.qty + data?.service_fee}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>


      </section>
    </>
  );
};

export default NewPaymentPage;

const PaymentInfo = ({ PaymentInfoData, setPaymentInfoData, setActive, active }) => {

  const HnaldeInfoChnage = (e) => {
    setPaymentInfoData({ ...PaymentInfoData, [e.target.name]: e.target.value })
  }

  return (
    <div class="space-y-4">
      <h2 class="text-xl font-semibold text-gray-500 dark:text-white">
        Delivery Details
      </h2>

      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <label
            for="your_name"
            class="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
           First Name*{" "}
          </label>
          <input
            type="text"
            id="your_name"
            name="fname"
            class="block w-full rounded-lg border-none outline-none p-2.5 text-sm bg-black text-[white]"
            placeholder="Your Name"
            value={PaymentInfoData.fname}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>

        <div>
          <label
            for="lname"
            class="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
            Last Name*{" "}
          </label>
          <input
            type="lname"
            id="lname"
            class="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
            placeholder="Last Name"
            name="lname"
            value={PaymentInfoData.lname}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>
        
      </div>
      <div>
          <label
            for="your_email"
            class="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
            Email*{" "}
          </label>
          <input
            type="email"
            id="your_email"
            class="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
            placeholder="Example@gmail.com"
            name="email"
            value={PaymentInfoData.email}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>
    </div>
  );
};



const Steps = ({ active, setActive }) => {






  return (
    <ol class="items-center  flex w-full max-w-2xl text-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:text-base">
      <li
        class={`after:border  whitespace-nowrap ${active >= 1 && "text-[red]"} flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b dark:text-primary-500 ${active >= 1 ? "after:border-[red]" : "after:border-gray-700"} sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10`}>
        <span class={`flex items-center after:mx-2 ${active >= 1 ? "!after:text-[red]" : "after:text-[green]"} after:content-['/'] dark:after:text-gray-500 sm:after:hidden`}>
          <svg
            class="me-2 h-4 w-4 sm:h-5 sm:w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Payment Info
        </span>
      </li>

      <li
        class={` whitespace-nowrap ${active >= 2 && "text-[red]"} flex items-center text-primary-700  after:border-b dark:text-primary-500  md:w-full xl:after:mx-10`}>
        <span class={`flex items-center after:mx-2 ${active >= 2 ? "!after:text-[red]" : "after:text-[green]"} after:content-['/'] dark:after:text-gray-500 sm:after:hidden`}>
          <svg
            class="me-2 h-4 w-4 sm:h-5 sm:w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Payment
        </span>
      </li>

      {/* <li class="flex shrink-0 items-center"    
    >
      <svg
        class="me-2 h-4 w-4 sm:h-5 sm:w-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      Payment
    </li> */}
    </ol>
  )
}

