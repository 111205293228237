import React from "react";
import Policy from "../Components/PagesCompoents/privicy/Policy";

const PrivicyPolicy = () => {
  return (
    <>
      <Policy />
    </>
  );
};

export default PrivicyPolicy;
