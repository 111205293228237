import React from "react";
import AboutImage from "../assets/About.png";
import AboutTicke from "../assets/aboutE.png";
import AboutMobile from "../assets/aboutm.png";

const About = () => {
  return (
    <div className="w-[100%] max-w-[2000px] !text-white md:px-[50px] px-[10px]  ">
      <div className="w-[100%] h-[320px] relative my-5 rounded-md overflow-hidden">
        <img src={AboutImage} alt="" className="w-[100%] h-[100%]" />
        <h1 className="absolute left-0 top-0 z-10 text-[60px] sm:text-[60px] text-[#aaa9a9] font-bold w-[100%] h-[100%] flex justify-center place-items-center">
          About Us
        </h1>
      </div>
      {/* ==============  */}
      {/* <div className=" flex justify-between place-items-center md:gap-3 my-5 flex-col gap-5 md:flex-row ">
        <div>
          <h2 className="text-redColor text-[30px] font-[600] mb-3 md:max-w-[500px] w-[100%]">
            We help you to manage your desired event
          </h2>
          <p className="text-[15px] md:max-w-[500px] w-[100%]">
            Our tailored solutions streamline every aspect, from planning to
            execution, ensuring seamless coordination. With our expertise, your
            event will exceed expectations, leaving a lasting impression on
            attendees. Trust us to handle the details, allowing you to focus on
            what matters most: creating memorable experiences.
          </p>
        </div>
        <div className="">
          <img src={AboutTicke} alt="" />
        </div>
      </div> */}
      {/* ===================  */}
      {/* <div className=" flex justify-between place-items-center md:gap-3 my-5 flex-col gap-5 md:flex-row">
        <div className="order-2 md:order-1">
          <img src={AboutMobile} alt="" />
        </div>
        <div className="order-1 md:order-2">
          <h2 className="text-redColor text-[30px] font-[600] mb-3 md:max-w-[500px] w-[100%]">
            We help you to manage your desired event
          </h2>
          <p className="text-[15px] md:max-w-[500px] w-[100%]">
            Our tailored solutions streamline every aspect, from planning to
            execution, ensuring seamless coordination. With our expertise, your
            event will exceed expectations, leaving a lasting impression on
            attendees. Trust us to handle the details, allowing you to focus on
            what matters most: creating memorable experiences.
          </p>
        </div>
      </div> */}

      {/* ===================  */}
      <div className="my-5">
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Welcome to InstaPass: Your Premier Ticketing Destination
          </h1>
          <p className="my-1">
            At InstaPass, we're more than just a ticketing platform – we're your
            passport to unparalleled experiences around the globe. As the
            leading wholesale ticketing platform, we offer a vast selection of
            tickets, accommodations, transportation, and activities, ensuring
            that your journey is seamless and unforgettable. With a focus on
            excellence and innovation, we've redefined the ticketing industry,
            surpassing competitors like Stub Hub, Ticketmaster, Vivid Seats,
            Seat Geek, and Via Gogo.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Who We Are
          </h1>
          <p className="my-1">
            At InstaPass, a shared mission drives our team: to empower our
            customers to discover and enjoy the world's most exciting events and
            destinations effortlessly. With years of industry expertise and a
            commitment to customer satisfaction, we've earned a reputation as a
            trusted partner for both buyers and sellers, facilitating seamless
            transactions and unforgettable experiences.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Our Competitive Edge
          </h1>
          <h2 className="italic text-[22px] font-thin">
            Here's why InstaPass stands out from the competition:
          </h2>
          <p className="my-2">
            . <span className="font-bold">Comprehensive Offerings</span>: While
            others focus solely on event ticketing, InstaPass offers a
            comprehensive array of services. From train and rail tickets to
            hotels, Airbnb accommodations, UBER/Lyft rides, activities, and
            airline tickets, we're your one-stop destination for all travel
            needs.
          </p>
          <p className="my-2">
            . <span className="font-bold">Digital Convenience:</span>: Embracing
            technology, InstaPass integrates cutting-edge features for user
            convenience. Our seamless integration with Google Wallet and Apple
            Wallet allows you to store tickets effortlessly, eliminating the
            need for physical tickets. Additionally, select tickets are
            available as NFTs with limited edition art/memorabilia, adding a
            unique touch to your experience.
          </p>
          <p className="my-2">
            . <span className="font-bold"> Exceptional Customer Service</span>:
            At InstaPass, customer satisfaction is our top priority. Our
            dedicated support team is available 24/7 to address any queries or
            concerns, ensuring a smooth and enjoyable experience for all users.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Why Choose InstaPass?
          </h1>
          <p className="my-2">
            . <span className="font-bold">Unbeatable Selection</span>: With
            access to a vast network of sellers and partners worldwide,
            InstaPass offers an unmatched selection of tickets and travel
            options, ensuring you never miss out on your favorite events or
            destinations.
          </p>
          <p className="my-2">
            . <span className="font-bold"> Competitive Pricing</span>: Despite
            our premium offerings, InstaPass remains committed to providing
            competitive prices. Our transparent pricing and flexible options
            empower you to find the best deals that fit your budget.
          </p>
          <p className="my-2">
            . <span className="font-bold"> Seamless Experience</span>: From
            browsing to booking, InstaPass offers a user-friendly interface and
            intuitive features, making the entire process effortless and
            stress-free. Whether you're a seasoned traveler or a first-time
            event-goer, our platform caters to all levels of expertise.
          </p>
          <p className="my-2">
            . <span className="font-bold">Innovation</span>: InstaPass is at the
            forefront of innovation, constantly pushing boundaries to deliver
            cutting-edge solutions. From digital ticketing to NFT integration,
            we ensure you're always ahead of the curve.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Join Us on the Journey
          </h1>
          <p className="my-2">
            At InstaPass, we believe that life is about creating unforgettable
            memories. With our commitment to excellence and innovation, we're
            here to accompany you on every step of your journey. Don't just
            dream – let InstaPass turn your dreams into reality. Join us today
            and embark on a journey filled with discovery, adventure, and
            endless possibilities.
          </p>
          <p className="my-2">
            . <span className="font-bold"> Competitive Pricing</span>: Despite
            our premium offerings, InstaPass remains committed to providing
            competitive prices. Our transparent pricing and flexible options
            empower you to find the best deals that fit your budget.
          </p>
          <p>
            Welcome to InstaPass – where every ticket is a gateway to
            extraordinary experiences.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
