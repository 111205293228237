import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllEvents } from "./redux/actions/Events";
import { GetUserLocationFunc, TokenVerify } from "./redux/actions/User";
import { GetAllFeaturedEvents } from "./redux/actions/Admin/AdminActions";
import { ScrollToTop } from "react-router-scroll-to-top";
import { Toaster } from "react-hot-toast";
import Home from "./pages/Home";
import Login from "./Components/accounts/Login";
import Navebar from "./Components/layout/Navebar";
import Registration from "./pages/Registration";
import Footer from "./Components/layout/Footer";
import Tickets from "./pages/Tickets";
import UserLocation from "./pages/UserLocation";
import GroupDetails from "./pages/GroupDetails";
import UserProfile from "./pages/UserProfile";
import Category from "./pages/Category";
import Index from "./DashBoard/Index";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import ForgotPassword from "./pages/ForgotPassword";
import NewsFeeds from "./pages/NewsFeeds";
import PrivicyPolicy from "./pages/PrivicyPolicy";
import ReactGA from "react-ga";
import Conditions from "./pages/Conditions";
import NewPaymentPage from "./pages/NewPaymentPage";
import DownloadInvoice from "./pages/DownloadInvoice";

const App = () => {
  const Allevents = useSelector((state) => state.events.AllEvents);
  const user = useSelector((state) => state.user.user);
  const UserLocationdata = useSelector((state) => state.user.userlocation);
  const [userIPAddress, setUserIPAddress] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("instapass_user_token");

  // === ga
  useEffect(() => {
    ReactGA.initialize("G-JBXCSBCJSB");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: "User",
      action: "Logged in",
    });
  }, []);

  // ========================= get the user location
  useEffect(() => {
    if(!userIPAddress){
      getVisitorIP();
    }
 // eslint-disable-next-line
  }, []);

  // ---- find the user ip
  const getVisitorIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();
      setUserIPAddress(data);
    } catch (error) {
      console.log("Failed to fetch IP", error);
    }
  };

  // --- find user location by ip
  useEffect(() => {
    if (userIPAddress) {
      getVisitorLocation();
    }
 // eslint-disable-next-line
  }, [userIPAddress]);

  const getVisitorLocation = async () => {
    try {
      const response = await fetch(`http://ip-api.com/json/${userIPAddress}`);
      const data = await response.json();
      dispatch(GetUserLocationFunc(data));
    } catch (error) {
      console.log("Failed to fetch location", error);
    }
  };

  useEffect(() => {
    if (Allevents && Allevents?.length === 0) {
      const page = 1;
      dispatch(GetAllEvents(page));
      dispatch(GetAllFeaturedEvents());
    }
    if (token) {
      dispatch(TokenVerify());
    }
 // eslint-disable-next-line
  }, []);



  useEffect(() => {
    const page = 1;
    dispatch(GetAllEvents(page, UserLocationdata));
 // eslint-disable-next-line
  }, [UserLocationdata]);



  return (
    <BrowserRouter>
      <Navebar />
      <ScrollToTop />
      <Toaster position="top-center" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/tickets/group.details/:id" element={<GroupDetails />} />
        <Route
          path="/tickets/group.details/:id/:loc/:date/:time/:name"
          element={<GroupDetails />}
        />
        <Route path="/location" element={<UserLocation />} />
        <Route path="/tickets/:name" element={<Category />} />
        {/* ----- user profile  */}
        <Route path="/user/profile" element={<UserProfile />} />
        {/* =========== Admin DashBoard  */}
        {user && user?.role === "admin" && (
          <Route path="/admin/dashboard" element={<Index />} />
        )}
        <Route path="/*" element={<NotFound />} />
        <Route path="/aboutUs" element={<About />} />
        <Route path="/terms&conditions" element={<Conditions />} />
        <Route path="/privacyPolicy" element={<PrivicyPolicy />} />
        <Route path="/newsfeed/:id" element={<NewsFeeds />} />
        <Route path="/payment" element={<NewPaymentPage />} />
        <Route path="/invoice/download/:orderId/:itemId" element={<DownloadInvoice />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
