import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style/profile.css";
import Sidebar from "./sidebar/Sidebar";
import Dashboard from "./DashBoard/Dashboard";
import AllOrders from "./AllOrders/AllOrders";
import AccountDetails from "./accountDeails/AccountDetails";
import UserAddress from "./UserAddress/UserAddress";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import { NavLink, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import UserAuth from "../PrivateRoutes/UserAuth";
import { TokenVerify } from "../../redux/actions/User";

const Index = () => {
  const user = useSelector((state) => state.user.user);
  const [active, setActive] = useState(2);
  const UserAvatar = ApiMediaURL + user?.Avatar;
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("instapass_user_token");
  const location = useLocation();
  const newPage = location.state?.data;

  useEffect(() => {
    if (newPage === 1) {
      setActive(newPage);
    }
  }, [newPage]);

  useEffect(() => {
    if (token) {
      dispatch(TokenVerify());
    }
  }, []);

  return (
    <UserAuth>
      <div
        className=" h-auto !bg-color1 !text-color2 z-40 profile w-[100%] overflow-x-auto
      py-5 px-5  relative
    "
      >
        <div
          className={`w-[100%]  bg-color1
          min-w-[300px] h-[500px] !sticky top-0
          ${
            showSidebar
              ? "profileSidebar_mobile profileSidebar"
              : "profileSidebar"
          }
          
      `}
        >
          <Sidebar
            active={active}
            setactive={setActive}
            sidebarShow={showSidebar}
            setShowSidebar={setShowSidebar}
          />
        </div>
        {/* ---- content  */}
        <div className="w-[100%] overflow-hidden">
          {/* ------- content  */}
          {active === 0 && (
            <div className="h-[90vh] flex justify-center place-items-center">
              <Dashboard />{" "}
            </div>
          )}
          <div className="w-[100%] !overflow-x-auto ">
            {active === 1 && <AllOrders />}
          </div>
          {active === 2 && <AccountDetails />}
          <div className="w-[100%] !overflow-x-auto ">
            {active === 3 && <UserAddress />}
          </div>
        </div>
      </div>
    </UserAuth>
  );
};

export default Index;
