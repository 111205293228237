import React, { useEffect } from "react";
import Index from "../Components/PagesComponents/Customer/Index";
import { AdminAllUserFunc } from "../../redux/actions/Admin/AdminUser";
import { useDispatch } from "react-redux";

const Customers = ({ setActive }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AdminAllUserFunc());
  }, []);
  return <Index setActive={setActive} />;
};

export default Customers;
