import React from "react";
import { LuLayout } from "react-icons/lu";
import { RiCalendarEventLine } from "react-icons/ri";
import { RiCouponLine } from "react-icons/ri";
import Logo from "../../Components/Logo/Logo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashBoardSidebar = ({ active, setActive }) => {

  return (
    <>
      <div className="sticky top-0 flex flex-col  left-0 min-w-[300px]  bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
        <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            <li className="px-5 md:block  h-[60px]  ">
              <div className="flex flex-row items-center h-[100%]">
                <div className="text-[25px] font-light tracking-wide text-gray-400 uppercase w-[100%] h-[100%] flex place-items-center justify-start">
                  Admin DashBoard
                </div>
              </div>
            </li>
            <li onClick={() => setActive(0)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 0 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Dashboard
                </span>
              </a>
            </li>
            
            <li onClick={() => setActive(1)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 1 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Orders
                </span>
              </a>
            </li>
            <li onClick={() => setActive(2)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 2 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Customers
                </span>
              </a>
            </li>

            <li className="px-5 hidden md:block">
              <div className="flex flex-row items-center mt-5 h-8">
                <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                  Settings
                </div>
              </div>
            </li>
            <li onClick={() => setActive(3)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
${active === 3 && "bg-color1 border-l-color2 border-l-4"}
                  `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Profile
                </span>
              </a>
            </li>
            <li onClick={() => setActive(5)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 5 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <LuLayout className="text-[20px]" />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Banner
                </span>
              </a>
            </li>
            <li onClick={() => setActive(6)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 6 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <RiCalendarEventLine className="text-[20px]" />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Latest Events
                </span>
              </a>
            </li>
            <li onClick={() => setActive(7)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 7 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <RiCalendarEventLine className="text-[20px]" />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Trending Events
                </span>
              </a>
            </li>
            <li onClick={() => setActive(8)}>
              <a
                href="#"
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-color1 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-color2 dark:hover:border-gray-800 pr-6
                ${active === 8 && "bg-color1 border-l-color2 border-l-4"}
                `}
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <RiCouponLine className="text-[20px]" />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Create CouponCode
                </span>
              </a>
            </li>
          </ul>
          <p className="mb-14 px-5 py-3 hidden md:block text-center text-xs">
            <Logo />
          </p>
        </div>
        
      </div>
    </>
  );
};

export default DashBoardSidebar;
