import React, { useEffect, useState } from "react";
import DashBoardSidebar from "./Sidebar/DashBoardSidebar";
import "./Styles/Index.css";
import DashBoard from "./pages/DashBoard";
import Header from "./Components/Header/Header";
import Customers from "./pages/Customers";
import Layout from "./pages/Layout";
import Orders from "./pages/Orders";
import AdminRoute from "../Components/PrivateRoutes/AdminRoute";
import { AdminAllUserFunc } from "../redux/actions/Admin/AdminUser";
import { useDispatch } from "react-redux";
import { AdminAllOrdersFunc } from "../redux/actions/Admin/AdminOrders";
import FeatureEvents from "./pages/FeatureEvents";
import TrendingEvent from "./pages/TrendingEvent";
import CouponCode from "./pages/CouponCode";
import CustomerOrders from "./Components/PagesComponents/Customer/CustomerOrders";
import OrderDetails from "./pages/OrderDetails";
import Profile from "./pages/Profile";

function Index() {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AdminAllUserFunc());
  }, []);
  useEffect(() => {
    dispatch(AdminAllOrdersFunc());
  }, []);
  return (
    <AdminRoute>
      <div className="absolute top-0 left-0 w-[100%] h-[100vh] overflow-y-hidden bg-color1 text-color2 z-[1000] dashboard_index max-w-[2000px]">
        {/* ---- sidebar  */}
        <div className="!w-[100%]">
          <DashBoardSidebar active={active} setActive={setActive} />
        </div>
        {/* ---- content  */}
        <div className="w-[100%] !overflow-x-hidden relative pb-[100px]">
          <Header setActive={setActive}/>
          <div>
            {active === 0 && (
              <DashBoard active={active} setActive={setActive} />
            )}
          </div>
          <div>{active === 2 && <Customers setActive={setActive} />}</div>
          <div>{active === 3 && <Profile />}</div>
          <div>{active === 5 && <Layout />}</div>
          <div className="w-[100%] !overflow-x-auto ">
            {active === 1 && <Orders setActive={setActive} />}
          </div>
          <div>{active === 6 && <FeatureEvents />}</div>
          <div>{active === 7 && <TrendingEvent />}</div>
          <div>{active === 8 && <CouponCode />}</div>
          <div>{active === 9 && <CustomerOrders />}</div>
          <div>{active === 10 && <OrderDetails />}</div>
        </div>
      </div>
    </AdminRoute>
  );
}

export default Index;
