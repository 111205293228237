import React, { useEffect, useState } from "react";
import Carousal from "../Components/PagesComponents/Layout/Carousal";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { SearchEvents } from "../../redux/actions/Events";
import Spinner from "../../Components/Loading/Spinner";
import toast from "react-hot-toast";
import {
  AdminCreateCarousal,
  GetAllCraousalEvent,
} from "../../redux/actions/Admin/AdminActions";
import { Loading } from "notiflix";


const Layout = () => {
  const [createCarousal, setCreateCarousal] = useState(false);
  const [image, setImage] = useState(null);
  const [search, setSearch] = useState("");
  const [Paragraph, setParagraph] = useState("");
  const [event, setEvent] = useState({});
  const [isOverLay, setisOverLay] = useState(true);
  const [showDropdown, setDropDown] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false)
  const [id, setId] = useState(null)
  const dispatch = useDispatch();
  const UserLocationdata = {};

  const searchFunction = () => {
    dispatch(SearchEvents(search, UserLocationdata));
  };
  const serachevents = useSelector((state) => state.events.serachevents);
  const isLoading = useSelector((state) => state.events.isLoading);

  const uploadImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSelectChange = (e) => {
    const filterEvent = serachevents?.filter(
      (item) => item.id == e.target.value
    );
    setEvent(filterEvent && filterEvent[0]);
  };
  const createCarousalFunction = async () => {
    if (!Paragraph || !event || !image) {
      return toast.error("Please Enter All fields");
    }
    if (isUpdate) {
      if (image) {
        const formdata = new FormData();
        console.log(event);
        formdata.append("event", JSON.stringify(event));
        formdata.append("paragraph", Paragraph);
        formdata.append("file", image);
        formdata.append("isOverLay", isOverLay);
        await dispatch(AdminCreateCarousal(formdata, `update/${id}`));
        setCreateCarousal(false);
        setSearch("");
        setParagraph("");
        setEvent({});
        setIsUpdate(false);
        setId(null);

      } else {
        const formdata = new FormData();
        formdata.append("event", JSON.stringify(event));
        formdata.append("paragraph", Paragraph);
        formdata.append("isOverLay", isOverLay);
        await dispatch(AdminCreateCarousal(formdata, `update/${id}`));
        setCreateCarousal(false);
        setSearch("");
        setParagraph("");
        setEvent({});
        setIsUpdate(false);
        setId(null);
      }

    } else {
      const formdata = new FormData();
      formdata.append("event", JSON.stringify(event));
      formdata.append("paragraph", Paragraph);
      formdata.append("file", image);
      formdata.append("isOverLay", isOverLay);
      await dispatch(AdminCreateCarousal(formdata, "create"));
      setCreateCarousal(false);
      setSearch("");
      setParagraph("");
      setEvent({});
      setIsUpdate(false);
    }

  };
  const adminloading = useSelector((state) => state.Admin.adminloading);
  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [adminloading]);

  // ---- cal get carousal
  useEffect(() => {
    dispatch(GetAllCraousalEvent());
  }, []);

  // --- edit carousal func 
  const EditCarousal = (item) => {
    setSearch(item?.event[0]?.name);
    setId(item?._id)
    setParagraph(item?.paragraph);
    setEvent(item?.event[0]);
    setCreateCarousal(true);
    setIsUpdate(true);
  }

  return (
    <div className="py-5 px-5 w-[100%]">
      <div className="flex justify-end place-items-center mx-4">
        <button
          className="my-4 bg-color4 px-5 py-2 cursor-pointer rounded-md"
          onClick={() => setCreateCarousal(true)}
        >
          Add New +
        </button>
        <button
          className="my-4 bg-color4 px-5 py-2 cursor-pointer rounded-md ml-3"
          onClick={() => dispatch(GetAllCraousalEvent())}
        >
          Reload 
        </button>
        {/* ----- modal  */}
        {createCarousal && (
          <div className="z-[99] fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
            <div className="max-h-full relative w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4">
              <div className="w-full">
                <div className="py-16 px-5">
                  <div className="">
                    <RxCross1
                      className="absolute top-4 right-4 text-[25px] cursor-pointer"
                      onClick={() => {
                        setCreateCarousal(false);
                        setSearch("");
                        setParagraph("");
                        setEvent({});
                        setIsUpdate(false);
                        setId(null);

                      }}
                    />
                    {/* ----------------  */}
                    <div className="w-[100%]">
                      <label
                        htmlFor="heading"
                        className="w-full block mb-1 pl-1 "
                      >
                        Search Event By Name
                      </label>
                      <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setDropDown(true);
                            dispatch(SearchEvents(search, UserLocationdata));
                          }
                        }}
                        type="text"
                        placeholder="Please Enter Event Name"
                        className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                      />
                    </div>
                    {/* ----- event options  */}
                    {showDropdown && (
                      <div className="w-full mt-2">
                        <label className="w-full block mb-1 pl-1">
                          Select Event
                        </label>
                        <select
                          className="w-full bg-color4 border-[1px] border-color5 outline-none py-2 px-3 rounded-md text-[#9CA3AF]"
                          onChange={(e) => handleSelectChange(e)}
                        // value={event}
                        >
                          <option value="">Plaese select an event</option>
                          {isLoading ? (
                            <option value={""} disabled>
                              <Spinner />
                            </option>
                          ) : (
                            <>
                              {serachevents?.map((item, index) => {
                                return (
                                  <option value={item?.id} key={index}>
                                    {item?.name}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    )}
                    {/* --------- paragrapg  */}
                    <div className="w-[100%] my-5">
                      <label
                        htmlFor="heading"
                        className="w-full block mb-1 pl-1 "
                      >
                        Carousal Paragraph
                      </label>
                      <input
                        value={Paragraph}
                        onChange={(e) => setParagraph(e.target.value)}
                        type="text"
                        placeholder="Please Enter Paragraph"
                        className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                      />
                    </div>
                    {/* ------ image  */}
                    <div className="w-[100%] my-5">
                      <label
                        htmlFor="heading"
                        className="w-full block mb-1 pl-1 "
                      >
                        Carousal Image
                      </label>
                      <input
                        onChange={uploadImageChange}
                        type="file"
                        accept="image/*"
                        multiple="fase"
                        placeholder="Please Enter Paragraph"
                        className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                      />
                    </div>
                    <div className="flex justify-start place-items-center gap-2 my-2">
                      <input
                        type="checkbox"
                        checked={isOverLay}
                        onChange={(e) => setisOverLay(!isOverLay)}
                      />
                      <label
                        htmlFor="ovrlay"
                        className="cursor-pointer"
                        onClick={() => setisOverLay(!isOverLay)}
                      >
                        Overlay
                      </label>
                    </div>

                    <button
                      className="mt-2 px-3 py-3 bg-color1 text-color2 w-full text-[17px] rounded-md"
                      onClick={createCarousalFunction}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mx-auto w-[100%]">
        <Carousal EditCarousal={EditCarousal} />
      </div>
    </div>
  );
};

export default Layout;
