import React, { useMemo } from "react";
import Lottie from "lottie-react";
import SVGGET from "../assets/NotFound/NotFound.json";

const NotFound = () => {
  const Memozation = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100%",
          zIndex: 80,
        }}
      >
        <Lottie
          animationData={SVGGET}
          loop={true}
          style={{ width: "300px", height: "auto" }}
          className="svges"
        />
      </div>
    );
  }, [SVGGET]);

  return Memozation;
};

export default NotFound;
