import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "./styles/Carousal02.css";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { CiClock1 } from "react-icons/ci";
import { IoTicketOutline } from "react-icons/io5";
import s1 from "../../../../assets/s1.png";
import s2 from "../../../../assets/s2.png";
import s3 from "../../../../assets/s3.png";
import s4 from "../../../../assets/s4.png";
import { ColorExtractor } from "react-color-extractor";
import { ReactSVG } from "react-svg";
import locationSvg from "../../../../assets/svg/location-dot.svg";
import calenderSvg from "../../../../assets/svg/calendar.svg";
import ClockSvg from "../../../../assets/svg/clock.svg";
import TicketSvg from "../../../../assets/svg/ticketsvg.svg";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../../../setting/GlobleVariable";
import {
  DeleteCraousalEvent,
  GetAllCraousalEvent,
} from "../../../../redux/actions/Admin/AdminActions";
import { NavLink } from "react-router-dom";
import { FaEdit } from "react-icons/fa";


const data = [
  {
    image: s4,
  },
  {
    image: s2,
  },
  {
    image: s3,
  },
  {
    image: s1,
  },
];

const Carousal = ({EditCarousal}) => {
  const [backgroundColors, setBackgroundColors] = useState([]);
  const carousalData = useSelector((state) => state.Admin.carousalData);

  const dispatch = useDispatch();

  const handleColors = (colors, index) => {
    if (colors && colors.length > 0) {
      setBackgroundColors((prevColors) => {
        const newColors = [...prevColors];
        newColors[index] = colors;
        return newColors;
      });
    }
  };
  useEffect(() => {
    dispatch(GetAllCraousalEvent());
  }, []);

  return (
    <div className={`Carousal02 rounded-[20px] h-[80vh] `}>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        // autoplay={{ delay: 3000 }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="w-[100%] h-[100%]"
        // onSlideChange={(swiper) => {
        //   const activeIndex = swiper.realIndex;
        //   const paginationBullets = document.querySelectorAll(
        //     ".swiper-pagination-bullet"
        //   );
        //   var parentView = document.querySelectorAll(".Carousal01");

        //   paginationBullets?.forEach((bullet, index) => {
        //     if (index === activeIndex) {
        //       bullet.style.setProperty(
        //         "background-color",
        //         backgroundColors[index] && backgroundColors[index][0],
        //         "important"
        //       );
        //       if (parentView && parentView[0]) {
        //         parentView[0].style.boxShadow = `0px 0px 7px 1px ${
        //           backgroundColors[index] && backgroundColors[index][0]
        //         }`;
        //       }
        //     } else {
        //       bullet.style.removeProperty("background-color");
        //     }
        //   });
        // }}
      >
        {carousalData &&
          carousalData?.map((item, index) => {
            const activeElement = document.querySelector(
              ".swiper-pagination-bullet-active"
            );
            var parentView = document.querySelectorAll(".Carousal01");
            if (parentView && parentView[0]) {
              parentView[0].style.boxShadow = `0px 0px 7px 1px #e40434 `;
            }
            // ${
            //     backgroundColors[0] && backgroundColors[0][0]
            //   }

            if (activeElement) {
              activeElement.style.setProperty(
                "background-color",
                // backgroundColors[0] && backgroundColors[0][0],
                "#e40434",
                "important"
              );
            }
            return (
              <SwiperSlide
                className="carousalbox bg-black z-10"
                key={index}
                onMouseEnter={() => {
                  var parentView = document.querySelectorAll(".Carousal01");
                  if (parentView && parentView[0]) {
                    parentView[0].style.boxShadow = `0px 0px 20px 2px #e40434 `;
                    // ${
                    //   backgroundColors[index] && backgroundColors[index][0]
                    // }
                  }
                }}
                onMouseLeave={() => {
                  var parentView = document.querySelectorAll(".Carousal01");
                  if (parentView && parentView[0]) {
                    parentView[0].style.boxShadow = `0px 0px 7px 1px #e40434 `;
                  }
                  // ${
                  //   backgroundColors[index] && backgroundColors[index][0]
                  // }
                }}
              >
                <MdDelete
                  className="text-[25px] absolute top-5 right-5 z-50 cursor-pointer text-redColor"
                  onClick={() => {
                    dispatch(DeleteCraousalEvent(item._id));
                    dispatch(GetAllCraousalEvent());
                  }}
                />
                <FaEdit
                  className="text-[25px] absolute top-5 right-[50px] z-50 cursor-pointer text-[green]"
                  onClick={() => EditCarousal(item)}
                />
                <div className="w-full h-full relative ">
                  <ColorExtractor
                    getColors={(colors) => handleColors(colors, index)}
                  >
                    <img
                      className="carousalbox_img_hero"
                      // src={item.image}
                      src={`${ApiMediaURL}/${item?.image}`}
                      alt=""
                    />
                  </ColorExtractor>
                 {
                  item?.isOverLay && 
                  <div
                  className="w-full h-full bg-gradient z-10 absolute top-0 left-0 opacity-[.8]"
                  style={{
                    background: `rgb(0,0,0)`,
                    backgroundImage: `linear-gradient(to right bottom,  ${
                      backgroundColors[index] ? " #e40434" : " #e40434"
                    }
                 
                  , #020202)`,
                  }}
                ></div>
                 }
                </div>
                <div className="carousalbox_content text-color2 relative  h-[100%]">
                  <div
                    className={`w-[250px] h-[250px] absolute z-[20] top-[-5px] right-[-5px] blur-[90px] opacity-[.9]`}
                    style={{
                      background: `rgb(0,0,0)`,
                      backgroundImage: `linear-gradient(to top, #020202, ${
                        backgroundColors[index]
                          ? "#e40434"
                          : // backgroundColors[index][0]
                            "#e40434"
                      }
                   
                )`,
                    }}
                  ></div>
                  <h1
                    className={`text-[33px] font-[700] mb-[10px]`}
                    style={{
                      color: "#e40434",
                      // backgroundColors &&
                      // backgroundColors[index] &&
                      // backgroundColors[index][0],
                    }}
                  >
                    {item && item?.event && item?.event[0]?.name?.length > 21
                      ? `${item?.event[0]?.name.slice(0, 20)}...`
                      : item?.event[0]?.name}
                  </h1>
                  <p className="text-[18px] text-color2 pr-2">
                    {item && item?.paragraph && item?.paragraph?.length > 100
                      ? `${item?.paragraph?.slice(0, 100)}...`
                      : item?.paragraph}
                  </p>
                  <div className="carousalbox_locations ">
                    <div className="carousalbox_locations_box">
                      {/* <IoLocationOutline /> */}
                      <ReactSVG src={locationSvg} />
                      <p
                        className=""
                        style={{
                          color: "#e40434",
                          // background: `-webkit-linear-gradient(top left, #333, ${
                          //   backgroundColors[index] &&
                          //   backgroundColors[index][0]
                          // } )`,
                          // WebkitBackgroundClip: "text",
                          // WebkitTextFillColor: "transparent",
                        }}
                      >
                        {item &&
                        item?.event &&
                        item?.event[0]?.venue?.location &&
                        item?.event[0]?.venue?.location?.length > 16
                          ? `${item?.event[0]?.venue?.location?.slice(0, 16)}..`
                          : item?.event[0]?.venue?.location}
                      </p>
                    </div>
                    <div className="carousalbox_locations_box">
                      {/* <MdOutlineCalendarMonth /> */}
                      <ReactSVG src={calenderSvg} />
                      <p
                        className=""
                        style={{
                          color: "#e40434",
                          // background: `-webkit-linear-gradient(top left, #333, ${
                          //   backgroundColors[index] &&
                          //   backgroundColors[index][0]
                          // } )`,
                          // WebkitBackgroundClip: "text",
                          // WebkitTextFillColor: "transparent",
                        }}
                      >
                        {item && item?.event[0]?.occurs_at_local.slice(0, 10)}
                      </p>
                    </div>
                    <div className="carousalbox_locations_box">
                      {/* <CiClock1 /> */}
                      <ReactSVG src={ClockSvg} />
                      <p
                        className=""
                        style={{
                          // color:
                          // backgroundColors &&
                          // backgroundColors[index] &&
                          // backgroundColors[index][0],
                          color: "#e40434",
                          // background: `-webkit-linear-gradient(top left, #333, ${
                          //   backgroundColors[index] &&
                          //   backgroundColors[index][0]
                          // } )`,
                          // WebkitBackgroundClip: "text",
                          // WebkitTextFillColor: "transparent",
                        }}
                      >
                        {item?.event[0]?.occurs_at_local
                          ? new Date(
                              item?.event[0]?.occurs_at_local
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })
                          : `------`}
                        {/* 4:00 PM */}
                      </p>
                    </div>
                  </div>

                  <button
                    // onClick={() =>
                    //   handleNavigation(item?.event[0]?.id, item?.event)
                    // }
                    className="my-[22px] z-[9999999] carousal_content_btn flex justify-center place-items-center px-[80px] py-[15px] rounded-[10.33px] cursor-pointer gap-[8px]"
                    style={{
                      backgroundColor: "#e40434",
                      // backgroundColors &&
                      // backgroundColors[index] &&
                      // backgroundColors[index][0],
                    }}
                  >
                    {/* <IoTicketOutline className="text-[25px]" /> */}
                    <ReactSVG src={TicketSvg} />
                    <p className="text-[15.5px] font-[400px] text_option">
                      Get Ticket
                    </p>
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Carousal;
