import React, { useEffect, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { CreateOrder } from "../../../../redux/actions/Events";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { ReactSVG } from "react-svg";
import crossSvg from "../../../../assets/svg/cross.svg";
import GoogleSvg from "../../../../assets/svg/Gpay.svg";
import PaypalSvg from "../../../../assets/svg/paypal.svg";
import VenmoSvg from "../../../../assets/svg/Apple.svg";
import CreditSvg from "../../../../assets/svg/Credit card.svg";

const CheckOutSidebar = ({
  SingleTicket,
  SetSingleTicket,
  payWith,
  setPayWith,
  getOrderData,
  setIsPayment,
}) => {
  const [qty, setqty] = useState(null);
  const dispatch = useDispatch();
  //   ------------- placeorder
  const placeorder = () => {
    if (Object.keys(SingleTicket).length > 0) {
      // if (!qty) {
      //   return toast.error("Please select the quantity");
      // }
      dispatch(
        CreateOrder(
          SingleTicket && SingleTicket[0]?.id,
          qty,
          SingleTicket && SingleTicket[0]?.retail_price,
          SingleTicket && SingleTicket[0]?.format,
          dispatch
        )
      );
    }
  };

  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    if (
      isloadingcreateOrder === true &&
      SingleTicket &&
      SingleTicket?.length > 0
    ) {
      Loading.standard("Loading Please Wait");
    } else {
      Loading.remove();
    }
  }, [isloadingcreateOrder]);

  // ---- chek the brower of the user
  const [browser, setBrowser] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let detectedBrowser = "Unknown";

    if (userAgent.indexOf("Chrome") > -1) {
      detectedBrowser = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      detectedBrowser = "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      detectedBrowser = "Mozilla Firefox";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident/") > -1
    ) {
      detectedBrowser = "Internet Explorer";
    }

    setBrowser(detectedBrowser);
  }, []);

  // console.log(SingleTicket);
  const HandleIsPayment = ()=>{
    if(!qty){
      return toast.error("Select the quantity");
    }
    const sendorderdata = {
      id: SingleTicket && SingleTicket[0]?.id,
      qty,
      retail_price: SingleTicket && SingleTicket[0]?.retail_price,
      format: SingleTicket && SingleTicket[0]?.format,
      service_fee: SingleTicket && SingleTicket[0]?.service_fee,
      // itemId: SingleTicket && SingleTicket[0]?.id,
    };
    getOrderData(sendorderdata);
    setIsPayment(true)

  }

  return (
    <>
      <div
        className={`py-5 px-3 !w-[390px] absolute top-0 z-10 !bg-color1 h-[100vh] !bg-transparent  !text-color2  checkout_sidebar_inner
      ${
        SingleTicket && SingleTicket?.length > 0
          ? "left-[0%] duration-500"
          : "left-[-150%] duration-500"
      }
    `}
      >
        <div className="flex justify-between place-items-start flex-col gap-0 mt-5">
          <div className="flex justify-between place-items-start w-full checkoutsidebar_header">
            <div>
              <h2 className="mb-1">
                Section {SingleTicket && SingleTicket[0]?.section} - Row{" "}
                {SingleTicket && SingleTicket[0]?.row}
              </h2>
              <p className="my-0 font-bold">
                ${SingleTicket && SingleTicket[0]?.retail_price}/ea
              </p>
            </div>

            <ReactSVG
              src={crossSvg}
              className="mt-1 mx-2 text-[25px] cursor-pointer"
              accordion
              onClick={() => SetSingleTicket([])}
            />
          </div>
          <div className="flex justify-end my-1 place-items-center w-full">
            <select
              className="bg-transparent outline-none !text-color2 py-2 px-5 !bg-color1  rounded-[12px] border-color5 border-[1px] border-shadowColor"
              value={qty}
              onChange={(e) => setqty(e.target.value)}
            >
              <option value="" className="">
                Select Ticket
              </option>
              {SingleTicket &&
                SingleTicket[0]?.splits?.map((item, index) => {
                  return (
                    <option
                      value={item && item}
                      key={index}
                      className="!border-b-[1px] border-b-color5"
                    >
                      {item && item}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {/* <div className="paymentmethodsidebardiv">
          <h2>Choose Payemnt Method</h2>
          <div className="paymentmethodbox">
            <div
              className={`paymentmethodboxchild border-[1px] border-shadowColor ${
                payWith === 1 && "apply_bg"
              }`}
              onClick={() => {
                if (!qty) {
                  return toast.error("Please select the quantity");
                }
                if (!user?.firstName) {
                  return toast.error("Please login to continue");
                }
                const sendorderdata = {
                  id: SingleTicket && SingleTicket[0]?.id,
                  qty,
                  retail_price: SingleTicket && SingleTicket[0]?.retail_price,
                  format: SingleTicket && SingleTicket[0]?.format,
                  service_fee: SingleTicket && SingleTicket[0]?.service_fee,
                  // itemId: SingleTicket && SingleTicket[0]?.id,
                };
                getOrderData(sendorderdata);
                setPayWith(1);
              }}
            >
              <ReactSVG src={CreditSvg} />
              <p>Credit Card</p>
            </div>
            <div
              className={`paymentmethodboxchild border-[1px] border-shadowColor ${
                payWith === 2 && "apply_bg"
              }`}
              onClick={() => {
                if (!qty) {
                  return toast.error("Please select the quantity");
                }
                if (!user?.firstName) {
                  return toast.error("Please login to continue");
                }
                const sendorderdata = {
                  id: SingleTicket && SingleTicket[0]?.id,
                  qty,
                  retail_price: SingleTicket && SingleTicket[0]?.retail_price,
                  format: SingleTicket && SingleTicket[0]?.format,
                  service_fee: SingleTicket && SingleTicket[0]?.service_fee,

                  // itemId: SingleTicket && SingleTicket[0]?.id,
                };
                getOrderData(sendorderdata);
                setPayWith(2);
              }}
            >
              <ReactSVG src={PaypalSvg} />
              <p>Paypal</p>
            </div>
            <div
              className={`paymentmethodboxchild border-[1px] border-shadowColor ${
                browser != "Safari" ? "!cursor-no-drop" : null
              }  ${browser == "Safari" && payWith === 3 && "apply_bg"}`}
              onClick={() => {
                if (browser === "Safari") {
                  if (!qty) {
                    return toast.error("Please select the quantity");
                  }
                  if (!user?.firstName) {
                    return toast.error("Please login to continue");
                  }
                  const sendorderdata = {
                    id: SingleTicket && SingleTicket[0]?.id,
                    qty,
                    retail_price: SingleTicket && SingleTicket[0]?.retail_price,
                    format: SingleTicket && SingleTicket[0]?.format,
                    service_fee: SingleTicket && SingleTicket[0]?.service_fee,

                    // itemId: SingleTicket && SingleTicket[0]?.id,
                  };
                  getOrderData(sendorderdata);
                  setPayWith(3);
                }
              }}
            >
              <ReactSVG src={VenmoSvg} />
              <p>Apple Pay</p>
            </div>
            <div
              className={`paymentmethodboxchild border-[1px] border-shadowColor ${
                payWith === 4 && "apply_bg"
              }`}
              onClick={() => {
                if (!qty) {
                  return toast.error("Please select the quantity");
                }
                if (!user?.firstName) {
                  return toast.error("Please login to continue");
                }
                const sendorderdata = {
                  id: SingleTicket && SingleTicket[0]?.id,
                  qty,
                  retail_price: SingleTicket && SingleTicket[0]?.retail_price,
                  format: SingleTicket && SingleTicket[0]?.format,
                  service_fee: SingleTicket && SingleTicket[0]?.service_fee,

                  // itemId: SingleTicket && SingleTicket[0]?.id,
                };
                getOrderData(sendorderdata);
                setPayWith(4);
              }}
            >
              <ReactSVG src={GoogleSvg} />
              <p>Google Pay</p>
            </div>
          </div>
        </div> */}

        <div
          className={`paymentmethodboxchild border-[1px] border-shadowColor my-[50px]`}
          onClick={HandleIsPayment}
        >
          {/* <ReactSVG src={CreditSvg} /> */}
          <p>Continue Payment</p>
        </div>
      </div>

      {/* =============  */}
    
    </>
  );
};

export default CheckOutSidebar;
