import * as React from "react";
// import logo from "../../assets/instapassLogo.png";
import Logo from "../Logo/Logo";
import { NavLink } from "react-router-dom";
import { MdMarkEmailRead, MdPhone } from "react-icons/md";
import { useEffect } from "react";
import "./styles/Footer.css"

const Footer = () => {

  useEffect(() => {
    // eslint-disable-next-line
    window.window.fnames = new Array();
    // eslint-disable-next-line
    window.ftypes = new Array();
    window.fnames[0] = 'EMAIL'; window.ftypes[0] = 'email'; window.fnames[1] = 'FNAME'; window.ftypes[1] = 'text'; window.fnames[2] = 'LNAME'; window.ftypes[2] = 'text'; window.fnames[3] = 'MMERGE3'; window.ftypes[3] = 'phone'; window.fnames[5] = 'BIRTHDAY'; window.ftypes[5] = 'birthday'; window.fnames[4] = 'COMPANY'; window.ftypes[4] = 'text'; window.fnames[6] = 'ADDRESS'; window.ftypes[6] = 'address';
    // var $mcj = window.jQuery.noConflict(true);
    const script = document.createElement("script");
    script.src = "https://affiliates.expediagroup.com/products/banners/assets/eg-affiliate-banners.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])




  const affiliateBannerRef = React.useRef(null);
  const depen = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner" : "affiliateBanner04")?.innerHTML;
  let affiliateBannerHtml = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner" : "affiliateBanner04")?.innerHTML;
  useEffect(() => {
    // setTimeout(() => {

    affiliateBannerRef.current.innerHTML = affiliateBannerHtml;
    affiliateBannerRef.current.style.display = "block !important";
    // }, 1000);

  }, [depen, affiliateBannerHtml, affiliateBannerRef])



  return (
    <div className="bg-color1 border-t-[1px] border-t-[#808080]">
      <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
          <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
            <div>
              <p className="font-medium tracking-wide text-gray-300">
                Category
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <NavLink
                    to={`/tickets/category=1`}
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Sports
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/tickets/category=54`}
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Concert
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/tickets/category=68`}
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Theater
                  </NavLink>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Movies
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-medium tracking-wide text-gray-300">
                Quick Links
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <NavLink
                    to="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/tickets"}
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    All Events
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/newsfeed/1"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    News Feed
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/aboutUs"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    About Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-medium tracking-wide text-gray-300">
                Quick Links
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <NavLink
                    to="/privacyPolicy"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/aboutUs"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    About Us
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/terms&conditions"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Terms & Conditions
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-medium tracking-wide text-gray-300">
                Contact Us
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <div
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200 flex justify-start place-items-center gap-2"
                  >
                    <MdPhone className="text-[22px] text-redColor" />
                    1(844) INSTA51
                  </div>
                </li>
                <li>
                  <div
                    className="text-gray-500  transition-colors duration-300 hover:text-deep-purple-accent-200 flex justify-start place-items-center gap-2"
                  >
                    <MdMarkEmailRead className="text-[22px] flex-shrink-0 text-redColor" />
                    <span className="footer_email">Admin@instapass.shop</span>
                  </div>
                </li>


              </ul>
            </div>
          </div>
          {/* <div>
              <p className="font-medium tracking-wide text-gray-300">Address</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Infopreneur
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Personal
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Forum
                  </a>
                </li>
              </ul>
            </div> */}
          <div className="md:max-w-md lg:col-span-2">
            {/* <span className="text-base font-medium tracking-wide text-gray-300">
              Subscribe for updates
            </span>
            <form className="flex flex-col mt-4 md:flex-row">
              <input
                placeholder="Email"
                required
                type="text"
                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                className="inline-flex bg-black items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                Subscribe
              </button>
            </form> */}
            <div id="mc_embed_shell" >
              <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
              {/* <style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}
      </style> */}
              <div id="mc_embed_signup">
                <form action="https://Getinstapass.us20.list-manage.com/subscribe/post?u=6c824f174d2c0d626598ffd2e&amp;id=0298d08a9c&amp;f_id=002522eaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate " target="_blank">
                  <div id="mc_embed_signup_scroll">
                    <div className="flex flex-col mt-4 md:flex-row mc-field-group">
                      <input type="email" name="EMAIL" placeholder="EMAIL" className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline" required="" />
                      <input type="submit" name="subscribe" className="inline-flex bg-black items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none" value="Subscribe" />

                    </div>
                    <div hidden="">
                      <input type="hidden" name="tags" value="408498" />
                    </div>
                    <div id="mce-responses" className="clear foot">
                      <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                      <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                    </div>
                    <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                      <input type="text" name="b_6c824f174d2c0d626598ffd2e_0298d08a9c" tabIndex="-1" value="" />
                    </div>
                  </div>
                </form>
              </div>
              <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" />
            </div>
            {/* <div className="px-[20px] w-[100%]">
           <p className="mt-4 border-2 w-[100%] text-sm text-gray-500 flex justify-between place-items-center gap-4 md:w-[260px]">
              <MdPhone className="text-[30px] text-redColor" />
              <span className="text-[19px] text-white">1(844) INSTA51</span>
            </p>
           
           </div> */}

          </div>
        </div>
        <div className="w-[100%] overflow-x-auto horizontal" ref={affiliateBannerRef}></div>

        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row">
          <div className="flex justify-start place-items-center">
            <Logo />
          </div>
          <div className="flex justify-start items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="https://x.com/GetInstapass"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/instapass.shop?igsh=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/Instapasstickets?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/instapass-app/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
              </svg>
            </a>
          </div>
        </div>
        <p className="text-sm text-gray-500 pb-[15px] text-left md:text-center">
          © 2024 InstaPass — InstaPass All rights reserved.
        </p>
      </div>

    </div>
  );
};

export default Footer;
