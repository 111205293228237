import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterEvent,
  GetAllEvents,
  SearchEvents,
  filterEventArrya,
} from "../../../redux/actions/Events";
import { updateFiltrationView } from "../../../redux/actions/State";
import { RxCross1 } from "react-icons/rx";
import { ReactSVG } from "react-svg";
import { GrLocation } from "react-icons/gr";
import { useMap } from "react-leaflet";
import CfSVG from "../../../assets/svg/calendarFilter.svg";
import filterSVG from "../../../assets/svg/filter.svg";
import axios from "axios";

const SidebarCategory = ({
  IsSearch = false,
  tickets = false,
  setIsFilter,
  startdate,
  setstartdate,
  enddate,
  setenddate,
  setpage,
  name = ""
}) => {
  const shearchingevents = useSelector((state) => state.events.serachevents);
  const copyofsearch = useSelector((state) => state.events.copyofsearch);
  const AllEvents = useSelector((state) => state.events.AllEvents);
  const CopyofAllEvents = useSelector((state) => state.events.CopyofAllEvents);

  const [location, setlocation] = useState("");
  const [locationObj, setlocationObj] = useState({});
  const [results, setResults] = useState([]);
  const [showLocationDiv, setShowLocationDiv] = useState(false);
  
  const moment = require("moment");
  const dispatch = useDispatch();


  const searchFunc = () => {

    const data = {
      startdate,
      enddate,
      location: {
        lon: locationObj?.lon,
        lat: locationObj?.lat,
      },
    };
    if (!enddate && !startdate && !location) {
      return;
    } else {
      if (location) {
        // setlocationObj({});
      }
      setIsFilter(true);
      setpage(1);
      const startDateFormatted = startdate
        ? moment(startdate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      const endDateFormatted = enddate
        ? moment(enddate).format("YYYY-MM-DD")
        : moment().add(60, "days").format("YYYY-MM-DD");
      dispatch(filterEventArrya());
      dispatch(
        FilterEvent(startDateFormatted, endDateFormatted, 1, results[0], IsSearch)
      );
    }
  };


  // ---- search location
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${location}`
        );
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (location.trim() !== "") {
      fetchLocations();
    }
  }, [location]);

  const handleSelect = (selectedLocation) => {
    setlocation(selectedLocation.display_name);
    setlocationObj(selectedLocation);
    setResults([]);
  };






  return (
    <>
      <div className="filtersidebar py-[30px] !bg-color1 !z-[999999999]">
        <div className="">
          <div className="my-5 flec flex-col gap-2 w-full">
            <div className="flex justify-between place-items-center ">
              <RxCross1
                className="text-[23px] cursor-pointer crosssidebar"
                onClick={() => dispatch(updateFiltrationView(false))}
              />
            </div>
            <div className="flex justify-start place-items-center gap-[6px]">
              <ReactSVG src={filterSVG} />
              <p className="filter_text">Filter</p>
            </div>
            <div className="my-2 flex justify-between place-items-end gap-[5px]">
              <div className="flex justify-center place-items-center">
                <ReactSVG src={CfSVG} />
              </div>
              <div className="">
                <p className="text-[14px] mb-1 text-color5 date_text">
                  Start Date
                </p>
                <input
                  value={startdate}
                  onChange={(e) => setstartdate(e.target.value)}
                  type="date"
                  className="text-color2  dateinput_category px-2 rounded-md w-full bg-transparent  py-2  outline-none"
                  style={{ color: "white" }}
                  id="start"
                />
              </div>
              <div className="">
                <p className="text-[14px] mb-1 text-color5 date_text">End Date</p>
                <input
                  value={enddate}
                  onChange={(e) => setenddate(e.target.value)}
                  type="date"
                  placeholder="Start date"
                  className="text-color2  dateinput_category px-2 rounded-md w-full bg-transparent  py-2  outline-none"
                  style={{ color: "white" }}
                  id="start"
                />
              </div>
            </div>
          </div>
          <div className="my-2 flex justify-start place-items-end gap-[10px]">
            <div className="flex justify-center place-items-center">
              <GrLocation className="text-[40px]" />
            </div>
            <div className="w-full">
              <p className="text-[14px] mb-1 text-color5 date_text">Location</p>
              <input
                value={location}
                id="location-input"
                onChange={(e) => {
                  setlocation(e.target.value);
                  setShowLocationDiv(false);
                }}
                type="text"
                placeholder="Search by location"
                className="text-color2  px-2 rounded-md !text-left !max-w-[220px] bg-transparent  py-2  outline-none"
                style={{ color: "white" }}
              />
            </div>
          </div>
          {!showLocationDiv && location && (
            <div
              className="bg-black mt-5 px-2 py-1 rounded-md h-[100px] overflow-hidden overflow-y-auto"
              style={{
                boxShadow: "0px 0px 5px 1px rgba(205, 41, 46, 0.45)",
              }}
            >
              <ul className="">
                {results.map((result) => (
                  <li
                    key={result.place_id}
                    onClick={() => {
                      handleSelect(result);
                      setShowLocationDiv(true);
                    }}
                    className="cursor-pointer my-2"
                  >
                    {result.display_name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* <UserLocation setlocation={setlocation} /> */}




        </div>
        <div className="my-5">
          <div className="flex flex-col gap-2">
            <div className=" my-3 flex justify-center place-items-center gap-3">
              <button
                onClick={searchFunc}
                className="filter-f-btn w-full px-[16px] py-2 bg-transparent  !bg-color1 mt-3 rounded-md cursor-pointer"
              >
                Filter
              </button>
              <button
                onClick={() => {
                  if (!startdate && !enddate && !location) {
                    return;
                  }
                  if (IsSearch) {
                    dispatch(SearchEvents(name, location));
                  } else {
                    setIsFilter(false);
                    setstartdate("");
                    setenddate("");
                    setlocation("");
                    dispatch(GetAllEvents(1, location));
                  }
                }}
                className="filter-f-btn w-full px-[16px] py-2 bg-transparent  !bg-color1 mt-3 rounded-md cursor-pointer"
              >
                Clear Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarCategory;




const SearchLocation = ({ userLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (userLocation.lat !== 0 && userLocation.lng !== 0) {
      map.setView([userLocation.lat, userLocation.lng], 13);
    }
  }, [userLocation, map]);
  
  return null;
};

const UserLocation = ({ setlocation }) => {
  const [userLocation, setUserLocation] = useState({
    lat: 33.9845124,
    lng: -118.3275139,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (query) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error getting location:", error);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault()
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 2) {
      handleSearch(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (location) => {
    setUserLocation({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lon),
    });
    setlocation({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lon),
    })
    setSearchQuery(location.display_name);
    setSuggestions([]);
  };

  const handleMapClick = (e) => {
    e.preventDefault()

    const { lat, lng } = e.latlng;
    setUserLocation({ lat, lng });
  };

  const handleMarkerDragEnd = (e) => {
    const { lat, lng } = e.target.getLatLng();
    setUserLocation({ lat, lng });
  };

 


  return (
    // <div className="border-2 h-[400px] w-[300px] relative">
    //   <form className="absolute top-3 px-2 right-0 z-[9999999999999] w-[100%] flex justify-center">
    //     <input
    //       type="text"
    //       value={searchQuery}
    //       onChange={handleInputChange}
    //       placeholder="Enter a location"
    //       className=" px-2 rounded-md w-[100%] text-color2 bg-black !text-left !max-w-[220px]  py-2  outline-none"


    //     />
    //     {suggestions.length > 0 && (
    //       <ul className="bg-black border rounded-md absolute w-full mt-[40px] mx-2 max-h-40 overflow-y-auto">
    //         {suggestions.map((suggestion) => (
    //           <li
    //             key={suggestion.place_id}
    //             className="cursor-pointer p-2 hover:bg-[#5858584d]"
    //             onClick={() => handleSelectSuggestion(suggestion)}
    //           >
    //             {suggestion.display_name}
    //           </li>
    //         ))}
    //       </ul>
    //     )}
    //   </form>
    //   <MapContainer
    //     center={[userLocation.lat, userLocation.lng]}
    //     zoom={0.25}
    //     style={{
    //       height: "400px",
    //       width: "100%",
    //     }}
    //     onClick={handleMapClick}
    //     zoomControl={false}
    //     id="leaflet-map"
    //   >
    //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    //     {userLocation.lat !== 0 && userLocation.lng !== 0 && (
    //       <Marker
    //         position={[userLocation.lat, userLocation.lng]}
    //         draggable={true}
    //         eventHandlers={{
    //           dragend: handleMarkerDragEnd,
    //         }}
    //       >
    //         <Popup>Your Location</Popup>
    //       </Marker>
    //     )}
    //     <SearchLocation userLocation={userLocation} />
    //   </MapContainer>
    // </div>
    <></>
  );
};
