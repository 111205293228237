import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { data } from "./DataCategory";
import "./Categoty.css";


function Category() {

  // eslint-disable-next-line
  const [subId, setShowSubId] = useState(null)
  const matchedItem = data?.find((item) => item.id === subId);

  return (
    <div className="!text-color2 !bg-color1 new_category my-[50px] w-full max-h-[400px]">
      <h2 className="text-redColor text-center text-[36px] font-[600] mb-[20px]">
        Categories
      </h2>
      {/* --- category  */}
      <div className="">
        <ul
          className={`new_category_parent  w-[100%] duration-75 !place-items-start  ${data?.length > 8 ? "!justify-start" : "!justify-center"
            }`}
        >
          {data?.map((item, index) => {
            return (
              <li className={`!w-[150px] h-[114px] relative  rounded-[13px] 
                  border-b-[5px] border-b-redColor  !min-w-[150px]
                  main_p_category dropdown dropdown-hover
                  `} key={index}>
                <NavLink
                  key={index}
                  to={`/tickets/category=${item?.id}`}

                  onMouseEnter={() => {
                    setShowSubId(item?.id);
                  }}
                >
                  <div
                    className={`new_category_box s_category_box  cursor-pointer  z-10  bg-transparent absolute top-0 left-0 w-[100%] h-[100%]`}
                  >
                    {item?.icon}
                    <p className={`text-[18px] font-[400] `}>{item?.name}</p>
                  </div>
                  <img
                    src={item?.img}
                    alt=""
                    className="absolute top-0 left-0  w-[100%] h-[100%] object-cover z-[1]"
                  />
                  <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-black opacity-[.6] z-[2]"></div>
                </NavLink>
                <div className="btn m-2"></div>
                <ul tabIndex={0} className="dropdown-content z-20 menu h-[300px] flex-nowrap overflow-x-hidden !overflow-y-auto  rounded-box  bg-[black] w-[250px] p-2 !mt-[47px]  text-[white]"
                  style={{
                    boxShadow: "0px 0px 10px 0px red"
                  }}
                >
                  {matchedItem && matchedItem?.subCategory && matchedItem?.subCategory?.length > 0 ? (
                    matchedItem?.subCategory?.map((item, index) => (
                      <li key={index} className="hover:bg-[#131212] !shrink-0 !w-[100%]  border-b-[1px] border-b-[#131212]">
                        <NavLink to={`/tickets/category=${item?.id}`}>{item?.name}</NavLink>
                      </li>
                    ))
                  ) : (
                    <div className="new_category_box shrink-0 !w-[100%] hover:border-b-2 hover:border-b-redColor cursor-pointer">
                      <p className="w-[100%] text-center">No more category found</p>
                    </div>
                  )}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Category;