import React from 'react'
import AccountDetails from '../../Components/UserProfile/accountDeails/AccountDetails'

const Profile = () => {
  return (
    <div className="px-5 py-5">
       <AccountDetails/>
    </div>
  )
}

export default Profile
