import toast from "react-hot-toast";
import {
  CREATE_ADMIN_CAROUSAL_FAIL,
  CREATE_ADMIN_CAROUSAL_REQUEST,
  CREATE_ADMIN_CAROUSAL_REQUEST_FAIL,
  CREATE_ADMIN_CAROUSAL_SUCCESS,
  CREATE_EVENTS_ERROR,
  CREATE_EVENTS_REQUEST,
  CREATE_EVENTS_REQUEST_FAIL,
  CREATE_EVENTS_SUCCESS,
  DELETE_CAROUSAL_FAIL,
  DELETE_CAROUSAL_REQUEST,
  DELETE_CAROUSAL_REQUEST_FAIL,
  DELETE_CAROUSAL_SUCCESS,
  DELETE_FEATURED_EVENTS_ERROR,
  DELETE_FEATURED_EVENTS_REQUEST,
  DELETE_FEATURED_EVENTS_REQUEST_FAIL,
  DELETE_FEATURED_EVENTS_SUCCESS,
  GET_CAROUSAL_FAIL,
  GET_CAROUSAL_REQUEST,
  GET_CAROUSAL_REQUEST_FAIL,
  GET_CAROUSAL_SUCCESS,
  GET_FEATURED_EVENTS_ERROR,
  GET_FEATURED_EVENTS_REQUEST,
  GET_FEATURED_EVENTS_REQUEST_FAIL,
  GET_FEATURED_EVENTS_SUCCESS,
} from "../../constant/AdminConstant";
import { ApiURL } from "../../../setting/GlobleVariable";

export const AdminCreateCarousal = (formdata,url) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ADMIN_CAROUSAL_REQUEST });
    const res = await fetch(`${ApiURL}/carousal/${url}`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: formdata,
    });
    dispatch({ type: CREATE_ADMIN_CAROUSAL_REQUEST_FAIL });
    const data = await res.json();
    console.log(data, res.status);
    if (res.status === 500) {
      return toast.error("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return toast.error(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: CREATE_ADMIN_CAROUSAL_SUCCESS, payload: data.orders });
      toast.success(data.message);
    }
  } catch (error) {
    dispatch({ type: CREATE_ADMIN_CAROUSAL_FAIL });
    console.log(error.message)
  }
};

// ==== get carousal
export const GetAllCraousalEvent = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CAROUSAL_REQUEST });
    const res = await fetch(`${ApiURL}/carousal/get/carousal`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: GET_CAROUSAL_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return console.log(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: GET_CAROUSAL_SUCCESS, payload: data.events });
    }
  } catch (error) {
    dispatch({ type: GET_CAROUSAL_FAIL });
  }
};

// ---- DELETE CAROUSAL
export const DeleteCraousalEvent = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CAROUSAL_REQUEST });
    const res = await fetch(`${ApiURL}/carousal/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: DELETE_CAROUSAL_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return toast.error("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return toast.error(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: DELETE_CAROUSAL_SUCCESS });
      toast.success(data.message);
    }
  } catch (error) {
    dispatch({ type: DELETE_CAROUSAL_FAIL });
  }
};

// ----- create event
export const CreateEventByAdminFeatured =
  (formdata, url) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_EVENTS_REQUEST });

      const res = await fetch(`${ApiURL}/event/${url}`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          token: localStorage.getItem("instapass_user_token"),
        },
        body: formdata,
      });
      dispatch({ type: CREATE_EVENTS_REQUEST_FAIL });
      const data = await res.json();
      console.log(data, res.status);
      if (res.status === 500) {
        return toast.error("Internal Server Error");
      }
      if (!data || res.status === 400 || res.status === 403) {
        return toast.error(data.message);
      }
      if (res.status === 200) {
        dispatch({ type: CREATE_EVENTS_SUCCESS, payload: data.allEvents });
        toast.success(data.message);
      }
    } catch (error) {
      dispatch({ type: CREATE_EVENTS_ERROR });
    }
  };
// --- create by
export const CreateEventByAdmin = (formdata, url) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EVENTS_REQUEST });

    const res = await fetch(`${ApiURL}/event/${url}`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: formdata,
    });
    dispatch({ type: CREATE_EVENTS_REQUEST_FAIL });
    const data = await res.json();
    console.log(data, res.status);
    if (res.status === 500) {
      return toast.error("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return toast.error(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: CREATE_EVENTS_SUCCESS, payload: data.allEvents });
      toast.success(data.message);
    }
  } catch (error) {
    dispatch({ type: CREATE_EVENTS_ERROR });
  }
};

// ---- get All events
export const GetAllFeaturedEvents = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FEATURED_EVENTS_REQUEST });
    const res = await fetch(`${ApiURL}/event/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: GET_FEATURED_EVENTS_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return console.log(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: GET_FEATURED_EVENTS_SUCCESS, payload: data.events });
    }
  } catch (error) {
    dispatch({ type: GET_FEATURED_EVENTS_ERROR });
  }
};

// ---- DELETE CAROUSAL
export const DeleteFeaturedEvent = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FEATURED_EVENTS_REQUEST });
    const res = await fetch(`${ApiURL}/event/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: DELETE_FEATURED_EVENTS_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return toast.error("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return toast.error(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: DELETE_FEATURED_EVENTS_SUCCESS, payload: data.events });
      toast.success(data.message);
    }
  } catch (error) {
    dispatch({ type: DELETE_FEATURED_EVENTS_ERROR });
  }
};
