import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

const PaymentMethods = ({ payWith , svg , title , data  , getOrderData , setPayWith , isPaymentInfoDataValue}) => {
  const user = useSelector((state) => state.user.user);
  const [browser, setBrowser] = useState("");
  useEffect(() => {
    const userAgent = navigator.userAgent;
    let detectedBrowser = "Unknown";

    if (userAgent.indexOf("Chrome") > -1) {
      detectedBrowser = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      detectedBrowser = "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      detectedBrowser = "Mozilla Firefox";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident/") > -1
    ) {
      detectedBrowser = "Internet Explorer";
    }

    setBrowser(detectedBrowser);
  }, []);

  return (
    <div
    className={`paymentmethodboxchild border-[1px] border-shadowColor ${
      browser != "Safari" && payWith === 3 || !isPaymentInfoDataValue ? "!cursor-no-drop" : null
    }  ${browser == "Safari" && payWith === 3 && "apply_bg"} `}
      onClick={() => {
        if(!isPaymentInfoDataValue){
          return;
        }
        if(browser !== "Safari" && payWith === 3){
            return;
        }
        // if (!user?.firstName) {
        //   return toast.error("Please login to continue");
        // }
        // const sendorderdata = {
        //   id: SingleTicket && SingleTicket[0]?.id,
        //   qty,
        //   retail_price: SingleTicket && SingleTicket[0]?.retail_price,
        //   format: SingleTicket && SingleTicket[0]?.format,
        //   service_fee: SingleTicket && SingleTicket[0]?.service_fee,

        //   // itemId: SingleTicket && SingleTicket[0]?.id,
        // };
        getOrderData(data);
        setPayWith(payWith);
      }}
    >
      <ReactSVG src={svg} />
      <p>{title}</p>
    </div>
  );
};

export default PaymentMethods;
