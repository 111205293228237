import React from "react";
import Index from "../Components/UserProfile/Index";

const UserProfile = () => {
  return (
    <div className="my-2 max-w-[2000px] mx-auto">
      <Index />
    </div>
  );
};

export default UserProfile;
