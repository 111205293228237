import React, { useRef } from "react";
import { useSelector } from "react-redux";
import EventCarosal from "./EventCarosal";
import CustomSliderbtn from "./CustomSliderbtn";
import "swiper/css";
import "swiper/css/pagination";
import "../TrendingEvents/styles/styles.css";
import "./LatestEvent.css";

const LatestEvent = () => {
  const swiperRef1 = useRef(null);
  const swiperRef2 = useRef(null);
  const swiperRef3 = useRef(null);
  const FeaturedEventsdata = useSelector((state) => state.Admin.featuredEvents);
  const filterTrndingEvent =
    FeaturedEventsdata &&
    FeaturedEventsdata?.filter((item, index) => item.isFeatured === true);
  const goNext1 = () => {
    if (swiperRef1.current && swiperRef1.current.swiper) {
      swiperRef1.current.swiper.slideNext();
    }
  };

  const goPrev1 = () => {
    if (swiperRef1.current && swiperRef1.current.swiper) {
      swiperRef1.current.swiper.slidePrev();
    }
  };

  const goNext2 = () => {
    if (swiperRef2.current && swiperRef2.current.swiper) {
      swiperRef2.current.swiper.slideNext();
    }
  };

  const goPrev2 = () => {
    if (swiperRef2.current && swiperRef2.current.swiper) {
      swiperRef2.current.swiper.slidePrev();
    }
  };

  const goNext3 = () => {
    if (swiperRef3.current && swiperRef3.current.swiper) {
      swiperRef3.current.swiper.slideNext();
    }
  };

  const goPrev3 = () => {
    if (swiperRef3.current && swiperRef3.current.swiper) {
      swiperRef3.current.swiper.slidePrev();
    }
  };

  return (
    <div className="latest_event text-color2 bg-color1">
      <h2 className="latest_event_heading text-redColor text-center">
        LATEST TICKETS
      </h2>
      {filterTrndingEvent && filterTrndingEvent?.slice(0, 12)?.length > 0 && (
        <CustomSliderbtn prev={goPrev1} next={goNext1}/>
      )}
      <EventCarosal swiperRef={swiperRef1} events={filterTrndingEvent?.slice(0,12)}/>
      {/* ===================== second  */}
      {filterTrndingEvent && filterTrndingEvent?.slice(12, 24)?.length > 0 && (
        <CustomSliderbtn prev={goPrev2} next={goNext2}/>
      )}
      <EventCarosal swiperRef={swiperRef2} events={filterTrndingEvent?.slice(12,24)}/>

      {/* =======================  */}
      {filterTrndingEvent && filterTrndingEvent?.slice(24, 70)?.length > 0 && (
        <CustomSliderbtn prev={goPrev3} next={goNext3}/>
      )}
      <EventCarosal swiperRef={swiperRef3} events={filterTrndingEvent?.slice(24,100)}/>

    </div>
  );
};

export default LatestEvent;
