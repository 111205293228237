import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteUserProfiel,
  UpdateUserRole,
} from "../../../../redux/actions/Admin/AdminUser";
// import Loading from "../../../../Components/Loading/Loading";
import { MdDeleteOutline } from "react-icons/md";
import { ApiMediaURL } from "../../../../setting/GlobleVariable";
import { Loading } from "notiflix";
import { GetUserOrderById } from "../../../../redux/actions/Admin/AdminOrders";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const CustomerTable = ({ setActive }) => {
  const dispacth = useDispatch();
  const AdminUsers = useSelector((state) => state.user.AdminAllUser);
  const adminloading = useSelector((state) => state.user.adminloading);
  const [dropdown, setdropdown] = useState(new Array([]).fill(false));
  // -- DropdownHandler
  const DropdownHandler = (index) => {
    const newDropdown = [...dropdown];
    newDropdown[index] = !newDropdown[index];
    setdropdown(newDropdown);
  };

  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [adminloading]);


  const handleDeleteUser = (id) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',

    }).then((result) => {
      if (result.isConfirmed) {
        dispacth(DeleteUserProfiel(id))
      }
    })
  }










  return (
    <>
      <div class="mt-4  bg-col">
        <div class="w-full overflow-hidden rounded-lg shadow-xs">
          <div class="w-full overflow-x-auto">
            <table class="w-full ">
              <thead>
                <tr class="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                  <th class="px-4 py-3">Id</th>
                  <th class="px-4 py-3">Avatar/Name</th>
                  <th class="px-4 py-3">Email</th>
                  <th class="px-4 py-3">Mobile Number</th>
                  <th class="px-4 py-3">Role</th>
                  <th class="px-4 py-3">Created_At</th>
                  <th class="px-4 py-3">Action</th>
                </tr>
              </thead>
              <tbody class="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800">
                {AdminUsers?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      class="bg-color3 border-b-color1  hover:bg-color1  text-color2"
                    >
                      <td class="px-4 py-3 text-sm">{index + 1}</td>
                      <td class="px-4 py-3">
                        <div class="flex items-center text-sm">
                          {item?.Avatar ? (
                            <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                              <img
                                class="object-cover w-full h-full rounded-full"
                                src={ApiMediaURL + "/" + item?.Avatar}
                                alt=""
                                loading="lazy"
                              />
                              <div
                                class="absolute inset-0 rounded-full shadow-inner"
                                aria-hidden="true"
                              ></div>
                            </div>
                          ) : (
                            <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                              <p class="object-cover w-full h-full rounded-full border-[1px] font-bold flex justify-center place-items-center">
                                {item && item?.firstName?.slice(0, 1)}
                              </p>
                              <div
                                class="absolute inset-0 rounded-full shadow-inner"
                                aria-hidden="true"
                              ></div>
                            </div>
                          )}
                          <div>
                            <p class="font-semibold">
                              {item && item?.firstName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="px-4 py-3 text-sm">{item && item?.email}</td>
                      <td class="px-4 py-3 text-sm">
                        {item && item?.phoneNumber}
                      </td>
                      <td class="px-4 py-3 text-xs ">
                        <span
                          class="cursor-pointer px-2 py-1 !w-[70px] font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                          onClick={() =>
                            dispacth(
                              UpdateUserRole(
                                item?._id,
                                item?.role === "user" ? "admin" : "user"
                              )
                            )
                          }
                        >
                          {" "}
                          {item && item?.role}{" "}
                        </span>
                      </td>
                      <td class="px-4 py-3 text-sm">
                        {item && item?.createdAt?.slice(0, 10)}
                      </td>
                      <td class="px-4 py-3 text-sm flex justify-start place-items-center gap-2">
                        <MdDeleteOutline
                          className="text-[23px] cursor-pointer text-[red]"
                          onClick={() => handleDeleteUser(item._id)}
                        />
                        <div class="relative inline-block text-left">
                          <div>
                            <button
                              type="button"
                              class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              id="menu-button"
                              aria-expanded="true"
                              aria-haspopup="true"
                              onClick={() => DropdownHandler(index)}
                            >
                              Options
                              <svg
                                class="-mr-1 h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          {dropdown[index] && (
                            <div
                              className="absolute right-0 z-10 mt-2 w-[150px]  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabIndex="-1"
                            >
                              <div
                                className="py-1 hover:bg-gray-300"
                                role="none"
                                onClick={() =>
                                  dispacth(
                                    UpdateUserRole(
                                      item?._id,
                                      item?.role === "user" ? "admin" : "user"
                                    )
                                  )
                                }
                              >
                                <p
                                  class="text-gray-700 block px-4 py-2 text-sm "
                                  role="menuitem"
                                  tabIndex="-1"
                                  id="menu-item-0"
                                >
                                  {item?.role === "user"
                                    ? "Make Admin"
                                    : "Make User"}
                                </p>
                              </div>
                              <div
                                className="py-1 hover:bg-gray-300"
                                role="none"
                                onClick={async () => {
                                  setActive(9);
                                  await dispacth(GetUserOrderById(item?._id));
                                  setdropdown(new Array([]).fill(false));
                                }}
                              >
                                <p
                                  class="text-gray-700 block px-4 py-2 text-sm "
                                  role="menuitem"
                                  tabIndex="-1"
                                  id="menu-item-0"
                                >
                                  Orders details
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTable;
