import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const SocialIcons = ({ item, Share }) => {
  return (
    <>
      <div
        className={` rounded-md px-1  left-[10px] flex flex-row gap-2 z-[99999] justify-center`}
      >
        <FacebookShareButton
          url={`http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`}
        >
          <FacebookIcon round={true} size={40} />
        </FacebookShareButton>
        <WhatsappShareButton
          url={`http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`}
        >
          <WhatsappIcon round={true} size={40} />
        </WhatsappShareButton>
        <TwitterShareButton
          url={`http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`}
        >
          <TwitterIcon round={true} size={40} />
        </TwitterShareButton>
        <EmailShareButton
          url={`http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`}
        >
          <EmailIcon round={true} size={40} />
        </EmailShareButton>
      </div>
    </>
  );
};

export default SocialIcons;
