import React from "react";
import { NavLink } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import BlogImage2 from "../../../assets/BlogsImages/blogimage2.png";
import BlogImage3 from "../../../assets/BlogsImages/blogimage3.png";
import "./TrendingBlog.css";

const TrendingBlog = () => {
  return (
    <div className="Trending_blog bg-color1 text-color2 my-5">
      <h2 className="Trending_heading text-redColor text-center  text-[36px] font-[600] mb-[20px]">
        LATEST NEWS
      </h2>
      {/* -------- grid area  */}
      <div className="trending_blog_grid_layout">
        {/* ----- left grid  */}
        <div className="left_grid_layout">
          <NavLink to="/newsfeed/1">
            <h2>
            Discover 7 Of The Most Historic Concert Hall Designs In The World
            </h2>
          </NavLink>
          <NavLink to="/newsfeed/1">
            <img
              src={
                "https://www.guidesblog.com/wp-content/uploads/2023/12/32836-1-450x330.webp"
              }
              alt=""
            />
          </NavLink>
          <NavLink to="/newsfeed/1">
            <button>Read more</button>
          </NavLink>
        </div>
        {/* ----- rigth  */}
        <div >
          <div className="right_grid_layout">
            <NavLink to="/newsfeed/2" className="right_grid_layout_box shrink-0">
              <img src={BlogImage2} alt="" />
              <div className="right_grid_layout_box_content ">
                <h2>
                How To Sell Concert Tickets and Make Money
                </h2>
                <p>
                If you are having problems selling tickets, it may be that you are like most salesmen who are not getting in...
                </p>
                <button>
                  Read More{" "}
                  <span>
                    <FaArrowRightLong />
                  </span>
                </button>
              </div>
            </NavLink>
            <NavLink to="/newsfeed/3" className="right_grid_layout_box shrink-0">
              <img src={BlogImage3} alt="" />
              <div className="right_grid_layout_box_content">
                <h2>
                All you need to know about Ticket Market Place
                </h2>
                <p>
                Do you have a concert coming up, but feel stuck on how to sell your concert tickets? Fortunately, whether you're selling...
                </p>
                <button>
                  Read More{" "}
                  <span>
                    <FaArrowRightLong />
                  </span>
                </button>
              </div>
            </NavLink>
            <NavLink to="/newsfeed/4" className="right_grid_layout_box shrink-0">
              <img
                src={
                  "https://tse3.mm.bing.net/th?id=OIP.RcsKO1g-jTQhV2ETg8q-6QHaFH&pid=Api&P=0&h=220"
                }
                alt=""
              />
              <div className="right_grid_layout_box_content">
                <h2>
                What You Should Do To Organize A Successful Concert Event
                </h2>
                <p>
                Listening to your favorite band or artist while on the road, is not the same as seeing them perform ....
                </p>
                <button>
                  Read More{" "}
                  <span>
                    <FaArrowRightLong />
                  </span>
                </button>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingBlog;
