import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import axios from "axios";

const SearchLocation = ({ userLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (userLocation.lat !== 0 && userLocation.lng !== 0) {
      map.setView([userLocation.lat, userLocation.lng], 13);
    }
  }, [userLocation, map]);

  return null;
};

const UserLocation = ({ searchValue }) => {
  const [userLocation, setUserLocation] = useState({
    lat: 33.9845124,
    lng: -118.3275139,
  });

  console.log(userLocation , "this is a user location")
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (query) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error getting location:", error);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault()
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 2) {
      handleSearch(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (location) => {
    setUserLocation({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lon),
    });
    setSearchQuery(location.display_name);
    setSuggestions([]);
  };

  const handleMapClick = (e) => {
    e.preventDefault()

    const { lat, lng } = e.latlng;
    setUserLocation({ lat, lng });
  };

  const handleMarkerDragEnd = (e) => {
    const { lat, lng } = e.target.getLatLng();
    setUserLocation({ lat, lng });
  };

  return (
    <div className="border-2 h-[400px] w-[300px] relative">
      <form className="absolute top-3 px-2 right-0 z-[9999999999999] w-[100%] flex justify-center">
        <input
          type="text"
          value={searchQuery}
          onChange={handleInputChange}
          placeholder="Enter a location"
          className=" px-2 rounded-md w-[100%] text-color2 bg-black !text-left !max-w-[220px]  py-2  outline-none"
          

        />
        {suggestions.length > 0 && (
          <ul className="bg-black border rounded-md absolute w-full mt-[40px] mx-2 max-h-40 overflow-y-auto">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                className="cursor-pointer p-2 hover:bg-[#5858584d]"
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.display_name}
              </li>
            ))}
          </ul>
        )}
      </form>
      <MapContainer
        center={[userLocation.lat, userLocation.lng]}
        zoom={0.25}
        style={{
          height: "400px",
          width: "100%",
        }}
        onClick={handleMapClick}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {userLocation.lat !== 0 && userLocation.lng !== 0 && (
          <Marker
            position={[userLocation.lat, userLocation.lng]}
            draggable={true}
            eventHandlers={{
              dragend: handleMarkerDragEnd,
            }}
          >
            <Popup>Your Location</Popup>
          </Marker>
        )}
        <SearchLocation userLocation={userLocation} />
      </MapContainer>
    </div>
  );
};

export default UserLocation;


