import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_USER_ORDER_FAIL,
  CREATE_USER_ORDER_REQUEST,
  CREATE_USER_ORDER_SUCCESS,
  GET_USER_ORDER_FAIL,
  GET_USER_ORDER_REQUEST,
  GET_USER_ORDER_SUCCESS,
} from "../constant/UserOrder";
import {
  PRINT_ORDERS_ERROR,
  PRINT_ORDERS_FAIL,
  PRINT_ORDERS_REQUEST,
  PRINT_ORDERS_SUCCESS,
} from "../constant/Orders";

const initailState = {
  isloading: false,
  UserOrders: [],
  isOrderCompleted: false,
};

export const UserOrders = createReducer(initailState, (builder) => {
  builder
    .addCase(CREATE_USER_ORDER_REQUEST, (state) => {
      state.isloading = true;

    })
    .addCase(CREATE_USER_ORDER_SUCCESS, (state) => {
      state.isloading = false;
      state.isOrderCompleted = true;
    })
    .addCase(CREATE_USER_ORDER_FAIL, (state) => {
      state.isloading = false;

    })
    // ---- get user all orders
    .addCase(GET_USER_ORDER_REQUEST, (state) => {
      state.isloading = true;
    })
    .addCase(GET_USER_ORDER_SUCCESS, (state, action) => {
      state.isloading = false;
      state.UserOrders = action.payload;
    })
    .addCase(GET_USER_ORDER_FAIL, (state) => {
      state.isloading = false;
    })
    // --- print order
    .addCase(PRINT_ORDERS_REQUEST, (state) => {
      state.isloading = true;
    })
    .addCase(PRINT_ORDERS_FAIL, (state) => {
      state.isloading = false;
    })
    .addCase(PRINT_ORDERS_SUCCESS, (state) => {
      state.isloading = false;
    })
    .addCase(PRINT_ORDERS_ERROR, (state) => {
      state.isloading = false;
    });
});
