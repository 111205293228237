import React from "react";

const Policy = () => {
  return (
    <div className="max-w-[2000px] bg-black text-color2 my-[30px] px-[10px] md:px-[50px]">
      <div className="w-[100%] h-[300px] flex justify-center place-items-center my-4 relative">
        <h1 className="text-[40px] font-bold md:text-[50px] w-[100%] h-[100%] bg-[rgba(0,0,0,.5)] flex justify-center place-items-center absolute top-0 left-0 z-10">
          {" "}
          Privacy & Policy
        </h1>
        <img
          src="https://www.ioscm.com/wp-content/uploads/2021/05/digital-privacy-1024x585.jpg"
          alt=""
          className="w-[100%] h-[100%]"
        />
      </div>

      <div>
        <b>
          <h1 className="text-[25px] text-redColor">
            INSTAPASS PRIVACY POLICY
          </h1>
        </b>
        <br />
        <p>
          InstaPass, LLC (“<b>InstaPass</b>,” “<b>we</b>,” “<b>us</b>” or “
          <b>our</b>”) is committed to respecting the privacy rights and
          concerns of all users of the InstaPass website where this Privacy
          Policy is posted (the “<b>Site</b>”), the mobile application (the “
          <b>App</b>”) and the software, products and services made available by
          InstaPass through the Site and the InstaPass communications platform
          through which end-users may connect (the “<b>Platform</b>”, and
          collectively with the Site and the App, the “<b>Service</b>”). As
          such, we have established and implemented this Privacy Policy to
          inform visitors to the Site and users of the Service how we use and
          protect the information we collect through the Service. By accessing
          the Site or using any of the Service, you consent to InstaPass’
          collection, use, storage, deletion and disclosure of information
          relating to you as set forth in this Privacy Policy. This Privacy
          Policy is effective as of the date set forth above and is only
          applicable to the Site and the Service and not to any other website
          that you may be able to access from the Site and the Service which may
          have its own data collection and use practices and policies.
        </p>
        <br /> <br />
        . This policy answers the following questions:
        <br />
        <ul>
          <li>
            . What information do we collect about you and how do we use it?
          </li>
          <li>. Will we share your personal information with third parties?</li>
          <li>
            . What choices do you have about the collection and use of your
            personal information?
          </li>
          <li>
            . What security measures do we take to safeguard your personal
            information?
          </li>
          <br />
          <br />
          <b>
            <u className="text-redColor">
              What Information Do We Collect About You And How Do We Use It?
            </u>
          </b>
          <br />
          <b className="my-2">Types of Information We Collect</b>
          <br />
          <p>
            {" "}
            Personal information means information that can be used to identify
            and contact you. You do not need to submit personal information to
            access the Service generally. However, you may be required to submit
            personal information to access certain aspects of the Service. We
            may collect non-personal information such as your mobile device
            operating system, browser type, IP addresses, transaction history
            and purchase history, and when you accessed the Service and from
            what physical location you accessed the Service. This information
            may be aggregated to measure the number of visits,
          </p>
          <br />
          <p>
            average time spent, page views, and other statistics about users of
            the Service. We also may use this data to monitor the Service’s
            performance and to make the Service easier and more convenient to
            use. We collect personal information when you create an account
            through the Service; participate in registration forms, contest
            forms, intake questionnaire forms, feedback forms or “Contact Us”
            forms; subscribe to the Service; add money to your InstaPass card;
            place ticket sale orders; fill in various online fields on our Site
            or App; or receive customer or technical support. To create a
            personal user account through the Service, we may require you to
            provide certain information, including your name, hobbies, email
            address, phone number, age, gender, birthdate, mailing address,
            location, username and password.
          </p>
          <br />
          <p>
            To make purchases through the Service, you will be required to
            provide a credit card number, debit card number, billing address,
            phone number or other payment information. No information of this
            type will be collected unless you have registered for aspects of the
            Service that require it. By submitting personal information through
            the Service, you authorize InstaPass to share this personal
            information for the purposes identified herein and on the Site and
            the Service, such as responding to user inquiries, processing
            transactions, distributing materials, requesting feedback, and
            administering contests, promotions or surveys.
          </p>
          <br /> We also offer features of the Service that allow you to link
          your account to Facebook, Twitter, or Instagram (“
          <b>Social Networks</b>”),
          <p>
            and may collect personal information you authorize to these Social
            Networks, which include, without limitation, your profile picture,
            your hobbies and interests, your gender and birthdate. By submitting
            personal information through the Service, you authorize InstaPass to
            receive and share this personal information for the purposes
            identified herein and, on the Site, and the Service, such as
            responding to user inquiries, processing transactions, distributing
            materials, and requesting feedback.
          </p>{" "}
          <br /> <br />
          <b className="text-redColor">Third Party Privacy Policies</b>
          <br />
          <p>
            Through the Site or the Service, you may be able to link to
            technology, software and services owned and controlled by third
            parties (“<b>Third Party Features</b>”). You may be permitted or
            required to submit personal information to access Third Party
            Features. Such use of Third Party Features and submission of
            information through Third Party Features will be subject to such
            applicable third party’s terms of use, terms of service and privacy
            policy. You agree to look solely to the applicable third party and
            not to InstaPass to enforce your rights in relation thereto. When
            you have clicked on a third party logo or URL displayed on the Site
            or the Platform, or accessible through the Service, which links you
            to any Third Party Features, our Privacy Policy and Terms of Use no
            longer apply and you must read the terms of use, terms of service
            and privacy policy of the third party to see how your personal
            information will be handled on their website.
          </p>
          <br />
          <b className="text-redColor">How We Use Your Personal Information</b>
          <br />
          <p>
            The personal information you provide to us will allow us to
            communicate with you and provide the Service to you, depending on
            the aspects of the Service for which you register. This includes
            alerting you of new products, services, features, or enhancements to
            the Service; verifying your identity; handling your customer service
            questions or issues; processing orders; requesting feedback;
            distributing newsletters; and administering contests, promotions or
            surveys. We may also use your email address and phone number as part
            of the Service in sending you messages about the Service and other
            general announcements.
          </p>{" "}
          <br />
          <p>
            We keep track of your interactions with us including, but not
            limited to, your activity within the Service. Except as set forth in
            this Privacy Policy, or unless we have your consent, we will not
            share your personal information with any person or entity other than
            those affiliated with us, entities acting on our behalf, and
            relevant third parties such as those needed to collect and maintain
            our servers and perform technology and related services. <br />
            We may use your personal information with non-personal information
            that we have collected in a manner such that the end-product does
            not personally identify you or any other user of the Service. We may
            make your personal information non-personally identifiable by either
            combining it with information about other users (aggregating your
            personal information with information about other users), or by
            removing characteristics (such as your name or email address) that
            make the information personally identifiable. This process is known
            as de-personalizing your information. <br /> You grant us a
            royalty-free, worldwide, perpetual, irrevocable and fully
            transferable right and license to use your personal information in
            connection with the creation and development of analytical and
            statistical analysis tools relating to the use of the customer data
            we collect in providing the Service (the “<b>Analytical Data</b>”).
            We are expressly authorized to make any commercial use of the
            Analytical Data including, without limitation, sharing the
            Analytical Data with third parties, provided that we do not sell,
            trade, or otherwise transfer from us any part of the Analytical Data
            that personally identifies any Service users.
          </p>
          <br /> <br />
          <b className="text-redColor">Do Not Track</b>
          <br />
          <p>
            In compliance with California legislation, AB370, effective January
            1, 2014, InstaPass’ practices in responding to “do not track”
            signals and collecting user information over time and across a
            network of websites when you visit the Site are as follows: We honor
            the request of “do not track” signals sent by your browser when you
            visit the Site. <br />
            Additionally, InstaPass does not collect user information about your
            online activities over time and across different websites or
            authorize third parties to do so.
          </p>
          <br /> <br />
          <b className="text-redColor">Service Analytics</b>
          <br />
          InstaPass uses Google Analytics or other analytics services to help us
          learn about who visits the Site or uses the Platform.
          <br /> <br />
          <b className="text-redColor">Cookies</b>
          <br />
          <p>
            “Cookies” are small bits of electronic information that a website
            sends to a visitor’s browser and are stored on the visitor’s hard
            drive. Like many websites, we may employ cookies in certain areas of
            the Site to allow us to provide information to make your online
            experience more convenient. For example, when you return to the Site
            after logging in, cookies provide information to the Site so that
            the Site will remember who you are on subsequent visits, speeding up
            or enhancing your experience of the Service by, for instance,
            tailoring content to you and keeping track of your shopping cart.{" "}
            <br /> Cookies may also be used to keep track of your login name and
            password. While InstaPass may use cookies to track your visit to the
            Site, this information does not identify you personally and you
            remain anonymous, even if you have previously submitted personal
            information to InstaPass through the Site or otherwise. You may
            still access the Service if you set your browser not to accept
            cookies. <br />
            Third parties whose websites are accessible through links on the
            Site may also use their own cookies when you link to their site or
            service. InstaPass does not control these third parties’ use of
            cookies and cannot guarantee that they will uphold the same privacy
            standards as InstaPass. InstaPass expressly disclaims any and all
            liability related to the collection, use or disclosure of
            information by third parties. You should review the privacy policies
            of any third-party sites that you visit through a link from the Site
            to understand how these third parties use cookies and the
            information they collect through cookies. <br />
            Most cookies are “session cookies” which means that they are
            automatically deleted at the end of each session. Most browsers are
            initially set to accept cookies. You can set your browser to notify
            you when you receive a cookie, giving you a chance to decide whether
            or not to accept it.
          </p>
          <br /> <br />
          <b className="text-redColor">Log Information</b>
          <br />
          <p>
            Our servers automatically record information that your browser sends
            whenever you visit the Site. This information includes your Internet
            Protocol address, domain names, your browser type and version, the
            search engine you used to find the Service, if any, which aspects of
            the Service you use and from where, and when and how long you use
            them. We use this information to monitor and analyze how users use
            the Service, to provide customer service and to maintain and improve
            the Service. We may also collect similar information from emails we
            may send to you which then help us track which emails are opened and
            which links are clicked by recipients. The information allows for
            more accurate reporting and improvement of the Service.
          </p>
          <br /> <br />
          <b className="text-redColor">Children’s Privacy</b>
          <br /> <br />
          <p>
            You must be at least 13 years of age to access the Service. You
            represent that you are the age of 13 or over and have the legal
            capacity to enter a contract in the jurisdictions where you reside.
            We do not knowingly collect personal information from children under
            13 or allow them to create an account for the Service. We take
            children’s privacy seriously and encourage parents to play an active
            role in their children’s online experience at all times.
          </p>
          <br />
          <br />
          <b className="text-redColor">
            <u>Will We Share Your Personal Information With Third Parties?</u>
          </b>
          <br />
          <b className="text-redColor">Sharing Your Information </b>
          <p>
            We use your personal information to provide the Service to you,
            deliver products and services you request, complete transactions,
            handle your customer service questions or issues and send
            communications to you about promotions, updates, or special offers
            from InstaPass that may be of interest to you. The personal
            information uploaded by you through the Service will be shared only
            with authorized persons to whom you specifically grant access. We
            may also use your depersonalized personal information to provide
            analyses of our users in the aggregate (including Analytical Data
            subject to the terms of this Privacy Policy) to prospective business
            partners, marketing partners and other third parties. To the extent
            applicable, we require these parties to comply with this Privacy
            Policy and appropriate confidentiality and security measures. We
            will also share the personal information we collect from you under
            the following circumstances, and you expressly authorize us to share
            your information as follows:
          </p>
          <br />
          <br />
          <ul>
            <li>
              <i>Protection of Rights.</i> We will share personal information if
              we have a good faith belief that (i) access, use, preservation or
              disclosure of such information is reasonably necessary to satisfy
              any applicable law, regulation, legal process, such as a court
              order or subpoena, or a request by law enforcement or governmental
              authorities, (ii) such action is appropriate to enforce our Terms
              of Service, including any investigation of potential violations
              thereof, (iii) such action is necessary to detect, prevent, or
              otherwise address fraud, security or technical issues associated
              with the Service, or (iv) such action is appropriate to protect
              the rights, property or safety of InstaPass, its employees, users
              of the Service or others.
            </li>
            <br />
            <li>
              <i>Asset Transfers.</i> If we become involved in a merger,
              acquisition or other transaction involving the sale of some or all
              of InstaPass’ assets, user information, including personal
              information collected from you through your use of the Service,
              could be included in the transferred assets. Should such an event
              occur, we will use reasonable means to notify you through email
              and/or a prominent notice on the Site and/or the App.
            </li>
            <br />
            <li>
              <i>Service Providers.</i> We may share your personal information
              with service providers (including our affiliates that provide
              services on our behalf) that we engage for the purpose of
              processing information on our and your behalf. Additionally, we
              may partner with other companies to process, analyze, and/or store
              data, including, but not limited to, Analytical Data. While
              providing services for us, these companies may access your
              personal information. To the extent applicable, we require these
              entities to comply with this Privacy Policy and appropriate
              confidentiality and security measures.
            </li>
            <p>
              Before we use your personal information for a purpose that is
              materially different than the purpose we collected it for or that
              you later authorized, we will provide you with the opportunity to
              opt out and/or ask you to provide your consent to allow us to use
              your personal information for that new purpose.
              <b>Additional Information You Should Know About Third Parties</b>
              The Service may contain direct links to websites operated by our
              partners and other entities. These third-party websites may
              collect information about you when you visit them or otherwise
              communicate with them. This Privacy Policy does not cover the
              information practices of third party websites linked to the Site
              or accessible through the Service. Although we encourage such
              third parties to adopt and follow their own privacy policies, we
              are not responsible for their collection and use of your personal
              information. You should refer to the privacy policies and
              statements of other websites or contact the respective webmasters
              of those websites to obtain information regarding their
              information collection, use and disclosure policies.
            </p>
            <br />
            <br />
            <b className="text-redColor">
              <u>
                What Choices Do You Have About the Collection and Use Of Your
                Personal Information?
              </u>
            </b>
            <br />
            <b className="text-redColor">Control of Your Information</b>
            <p>
              You may update or delete your personal information or modify your
              account preferences for the Service by accessing the account
              interface provided through the Service, calling us at [(---) ---
              ----] or writing to us at [support@instapass.com]. Please note
              that if you disable your account, we will keep your contact
              information on file, and may retain other account information for
              a reasonable period of time afterward for the purpose of internal
              account management and fraud prevention activities.
            </p>
            <br />
            <br />
            <b>Opting Out Of Receiving Communications From Us</b>
            If you no longer want to receive our newsletter, emails or other
            announcements, you may unsubscribe by writing to us at
            support@instapass.com, calling our support phone number at [(---)
            --- ----]. Please note that you cannot unsubscribe from certain
            correspondence from us, including messages relating directly to your
            account.
            <br />
            <br />
            <b className="text-redColor">
              <u>Mediation</u>
            </b>
            <br />
            <p>
              You agree that, in the event any dispute or claim arises out of or
              relating to this Privacy Policy, you and InstaPass will attempt in
              good faith to negotiate a written resolution of the matter
              directly between the parties. You agree that if the matter remains
              unresolved for forty-five (45) days after notification (via
              certified mail or personal delivery) that a dispute exists, all
              parties shall join in mediation services in Los Angeles,
              California with a mutually agreed mediator in an attempt to
              resolve the dispute. Should you file any arbitration claims, or
              any administrative or legal actions without first having attempted
              to resolve the matter by mediation, then you agree that you will
              not be entitled to recover attorneys’ fees, even if you would
              otherwise be entitled to them.
            </p>
            <br />
            <br />
            <b className="text-redColor">
              <u>
                What Security Measures Do We Take To Safeguard Your Personal
                Information?
              </u>
            </b>
            <br />
            <p>
              The personal information that you provide to us is stored on
              servers which are protected by protocols and procedures designed
              to ensure the security of such information. We will store your
              personal information for so long as it is necessary to provide you
              our Service and will periodically destroy any old personal
              information that we hold in relation to you in accordance with our
              company policies, including when it is no longer necessary in
              order to offer you the same high-quality service. In most cases,
              we will keep one archival copy of your information to allow us to
              comply with laws or respond to legal processes. Following the
              termination of our relationship we will ensure that we destroy all
              of the personal information that we hold in relation to you within
              a reasonable period of time or when required by law. We restrict
              access to personal information to InstaPass employees, independent
              contractors and agents who need to know this information in order
              to develop, operate and maintain the App, Site or Service. All
              InstaPass personnel who have access to this information are
              trained in the maintenance and security of such information.
              Access to such personal information is minimized whenever
              possible. We do not retain payment information such as your credit
              card number, and only retain the secure token relating to your
              payment information provided by our payment processor for a
              limited period after a transaction is completed. In the event that
              personal information you provide to us is compromised as a result
              of a breach of security, when appropriate we will take reasonable
              steps to investigate the situation, notify you and take the
              necessary steps to comply with any applicable laws and
              regulations.
            </p>
            <br />
            <br />
            <b className="text-redColor">
              <u>
                California Civil Code Section 1798.83 (“Shine the Light Law”){" "}
              </u>
            </b>
            <br />
            <p>
              California’s Shine the Light Law permits California residents to
              request and obtain from us details about what personal information
              is shared with third parties and, in some cases, affiliates, for
              those third parties’ and affiliates’ own direct marketing
              purposes. California residents are allowed to request this
              information once a year, free of charge. If applicable, this
              information would include a list of the categories of personal
              information that was shared and the names and addresses of all
              third parties with which we shared information in the immediately
              preceding calendar year. If you are a California resident and
              would like to make such a request, please submit your request to
              [----@instapass.com]. InstaPass will limit sharing with its
              affiliates to the extent required by California law. InstaPass
              will not share your personal information for joint marketing with
              other financial institutions.
            </p>
            <b className="text-redColor">
              <u>Notification of Changes</u>
            </b>
            This Privacy Policy may change from time to time and we will post
            all changes on the Site and/or the App.
            <b className="text-redColor">
              <u>Questions? Contact Us</u>
            </b>
            If you have any questions about our privacy practices or this
            Privacy Policy, please contact us by email at legal@instapass.com.
            <b className="text-redColor">
              <u>Terms of Service</u>
            </b>
            Please read the Terms of Use governing the use of the Site and the
            Service.
          </ul>
        </ul>
        <br />
        <br />
        <b className="text-redColor">
          <u>International Data Transfers</u>
        </b>
        <p>
          If we transfer your personal information to countries outside of your
          country of residence, we will ensure that such transfers comply with
          applicable data protection laws. This may include implementing
          Standard Contractual Clauses or other appropriate safeguards to
          protect your personal information.
        </p>
        <br />
        <br />
        <b className="text-redColor">
          <u>Notification of Changes</u>
        </b>
        <p>
          This Privacy Policy is subject to change, and all updates will be
          posted on the Site or App.
        </p>
        <br />
        <br />
        <b className="text-redColor">
          <u>Updates to the Privacy Policy </u>
        </b>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. Any updates will be posted on the Site or App with a revised
          effective date. You will be notified of any material changes to the
          Privacy Policy, and you will have the opportunity to review and accept
          the revised policy before it takes effect.
        </p>
        <br />
        <br />
        <b className="text-redColor">
          <u>Questions? Contact Us </u>
        </b>
        <p>
          For inquiries regarding our privacy practices or this Privacy Policy,
          please email us at support@instapass.shop.
        </p>
        <br />
        <br />
        <b className="text-redColor">
          <u>Training and Awareness </u>
        </b>
        <p>
          We are committed to providing ongoing training and awareness programs
          to our staff regarding privacy and data protection principles. Our
          employees are trained to understand the importance of confidentiality
          and compliance with this Privacy Policy in all aspects of our
          operations.
        </p>
        <br />
        <br />
        <b className="text-redColor">
          <u>Terms of Service</u>
        </b>
        <p>Please review the Terms of Use governing Site and Service usage.</p>
      </div>
    </div>
  );
};

export default Policy;
