import React from "react";
import Carousal01 from "../Components/Carousal/Carousal01";
import Category from "../Components/CategorySection/Category";
import TrendingEvent from "../Components/Home/TrendingEvents/TrendingEvent";
import LatestEvent from "../Components/Home/LatestEvent/LatestEvent";
import TrendingBlog from "../Components/Home/TrendingBlogs/TrendingBlog";
import "../Components/Home/Section2.css";

const Home = () => {
  return (
    <div className="!bg-color1 !overflow-x-hidden w-[100%]  !z-50  max-w-[2000px] mx-auto  2xl:px-[100px] px-[50px]  main_home">
      <Carousal01 />
      <Category />
      <TrendingEvent />
      <LatestEvent />
      <TrendingBlog />
    </div>
  );
};

export default Home;






