export const CREATE_USER_ORDER_REQUEST = "CREATE_USER_ORDER_REQUEST";
export const CREATE_USER_ORDER_REQUEST_FAIL = "CREATE_USER_ORDER_REQUEST_FAIL";
export const CREATE_USER_ORDER_SUCCESS = "CREATE_USER_ORDER_SUCCESS";
export const CREATE_USER_ORDER_FAIL = "CREATE_USER_ORDER_FAIL";

// --- get al orders for the user

export const GET_USER_ORDER_REQUEST = "GET_USER_ORDER_REQUEST";
export const GET_USER_ORDER_SUCCESS = "GET_USER_ORDER_SUCCESS";
export const GET_USER_ORDER_FAIL = "GET_USER_ORDER_FAIL";
