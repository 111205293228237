import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Spinner from "../../Components/Loading/Spinner";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  CreateEventByAdmin,
  DeleteFeaturedEvent,
  GetAllFeaturedEvents,
} from "../../redux/actions/Admin/AdminActions";
import { SearchEvents } from "../../redux/actions/Events";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import { MdDeleteOutline } from "react-icons/md";
import { Loading } from "notiflix";

function TrendingEvent() {
  const [createevent, setcreateevent] = useState(false);
  const [search, setSearch] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [showDropdown, setDropDown] = useState(null);
  const [Paragraph, setParagraph] = useState("");
  const [image, setImage] = useState(null);
  const [event, setEvent] = useState({});
  const serachevents = useSelector((state) => state.events.serachevents);
  const isLoading = useSelector((state) => state.events.isLoading);
  const FeaturedEventsdata = useSelector(
    (state) => state.Admin.featuredEvents
  )?.filter((item) => item?.isFeatured === false);

  const dispatch = useDispatch();

  const uploadImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSelectChange = (e) => {
    const filterEvent = serachevents?.filter(
      (item) => item.id == e.target.value
    );
    setEvent(filterEvent && filterEvent[0]);
  };

  const createeventFunction = () => {
    if (!Paragraph || !event || !image) {
      return toast.error("Please Enter All fields");
    }
    const formdata = new FormData();
    const url = "create";
    formdata.append("event", JSON.stringify(event));
    formdata.append("paragarph", Paragraph);
    formdata.append("file", image);
    formdata.append("isFeatured", false);
    dispatch(CreateEventByAdmin(formdata, url));
  };

  const UserLocationdata = {};

  useEffect(() => {
    dispatch(GetAllFeaturedEvents());
  }, []);

  const adminloading = useSelector((state) => state.Admin.adminloading);
  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [adminloading]);

  return (
    <div className="px-2">
      <div className="w-full flex justify-end place-items-end my-2">
        <button
          className="my-4 bg-color4 px-5 py-2 cursor-pointer rounded-md"
          onClick={() => setcreateevent(true)}
        >
          Add New +
        </button>
      </div>
      {/* ============================= table  */}
      <div className="my-4 px-3">
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right  bg-color1 text-color2">
            <thead class="text-xs text-color2 uppercase bg-color3 rounded-md">
              <tr>
                <th scope="col" class="px-1 py-3">
                  ID
                </th>
                <th scope="col" class="px-2 py-3">
                  Image
                </th>
                <th scope="col" class="px-6 py-3">
                  Event Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Desicription
                </th>
                <th scope="col" class="px-2 py-3">
                  isFeatured
                </th>
                <th scope="col" class="px-2 py-3">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {FeaturedEventsdata &&
                FeaturedEventsdata?.map((item, index) => {
                  return (
                    <tr class=" border-b text-color2 bg-color1">
                      <td class="px-1 py-4">
                        #{item && item?._id?.slice(0, 4)}
                      </td>
                      <td class="px-2 py-4  ">
                        <img
                          src={`${ApiMediaURL}/${item?.image}`}
                          alt=""
                          className="w-[30px] h-[30px] rounded-full border-[1px]"
                        />
                      </td>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium  whitespace-nowrap"
                      >
                        {item?.event &&
                        item?.event[0]?.name &&
                        item?.event[0]?.name?.length > 30
                          ? `${item?.event[0]?.name?.slice(0, 30)}...`
                          : item?.event[0]?.name}
                      </th>
                      <td class="px-6 py-4">
                        {item && item?.paragarph && item?.paragarph?.length > 30
                          ? `${item?.paragarph?.slice(0, 30)}...`
                          : item?.paragarph}
                      </td>
                      <td class="px-2 py-4">
                        {item && item?.isFeatured === true ? (
                          <p className="text-[green]">True</p>
                        ) : (
                          <p className="text-[red]">false</p>
                        )}
                      </td>
                      <td class="px-2 py-4">
                        <MdDeleteOutline
                          className="text-[23px] text-[red] cursor-pointer"
                          onClick={() => {
                            dispatch(DeleteFeaturedEvent(item?._id));
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {/* ================= modal  */}
      {createevent && (
        <div className="z-[99] fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div className="max-h-full relative w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4">
            <div className="w-full">
              <div className="py-16 px-5">
                <div className="">
                  <RxCross1
                    className="absolute top-4 right-4 text-[25px] cursor-pointer"
                    onClick={() => setcreateevent(false)}
                  />
                  {/* ----------------  */}

                  <div className="w-[100%]">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      Search Event By Name
                    </label>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setDropDown(true);
                          dispatch(SearchEvents(search, UserLocationdata));
                        }
                      }}
                      type="text"
                      placeholder="Please Enter Event Name"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  {/* ----- event options  */}
                  {showDropdown && (
                    <div className="w-full mt-2">
                      <label className="w-full block mb-1 pl-1">
                        Select Event
                      </label>
                      <select
                        className="w-full bg-color4 border-[1px] border-color5 outline-none py-2 px-3 rounded-md text-[#9CA3AF]"
                        onChange={(e) => handleSelectChange(e)}
                        // value={event}
                      >
                        <option value="">Plaese select an event</option>
                        {isLoading ? (
                          <option value={""} disabled>
                            <Spinner />
                          </option>
                        ) : (
                          <>
                            {serachevents?.map((item, index) => {
                              return (
                                <option value={item?.id} key={index}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>
                    </div>
                  )}
                  {/* --------- paragrapg  */}
                  <div className="w-[100%] my-5">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      Carousal Paragraph
                    </label>
                    <input
                      value={Paragraph}
                      onChange={(e) => setParagraph(e.target.value)}
                      type="text"
                      placeholder="Please Enter Paragraph"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  {/* ------ image  */}
                  <div className="w-[100%] my-5">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      Carousal Image
                    </label>
                    <input
                      onChange={uploadImageChange}
                      type="file"
                      accept="image/*"
                      multiple="fase"
                      placeholder="Please Enter Paragraph"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  {/* <div className="w-[100%] my-5 flex justify-start place-items-center gap-2">
                    <input
                      type="checkbox"
                      checked={isFeatured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                      placeholder="Please Enter Paragraph w-[20px] h-[20px]"
                      className="py-2 px-3 bg-color4  border-color5 rounded-md outline-none "
                    />
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 cursor-pointer"
                      onClick={() => setIsFeatured(!isFeatured)}
                    >
                      Make This Latest
                    </label>
                  </div> */}

                  <button
                    className="mt-2 px-3 py-3 bg-color1 text-color2 w-full text-[17px] rounded-md"
                    onClick={createeventFunction}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrendingEvent;
